import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Button,
  Typography,
  Grid,
  Box,
  IconButton,
  Divider,
  Checkbox
} from '@mui/material'
import { Launch } from '@mui/icons-material'
import { createCheckout, createAltCheckout } from '../../api/subscription'
import ParentAPI from '../../api/parentApi'
import LoadingComp from '../util/loading'
//import getDayString from '../../utils/getDayString'
import dateHandler from '../../utils/dateHandler'
import cards from '../util/cards'
import CONSTANTS from '../../constants'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

const ConfirmDetails = ({ setCurrTab }) => {
  const data = useSelector((state) => state.subscription.checkoutData)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const useAltPayment = useSelector((state) => state.cart.useAltPayment)
  const BigCard = cards.bigCard

  const [student, setStudent] = useState(null)
  const [activities, setActivities] = useState([])
  const [termsCheck, setTermsCheck] = useState(false)
  const [termsError, setTermsError] = useState(false)

  const handleSubmit = async () => {
    if (!termsCheck) {
      setTermsError(true)
    } else {
      ReactGA.event({
        category: 'button',
        action: 'Membership Checkout'
      })
      ReactPixel.track('InitiateCheckout', {
        content_category: 'Membership_Checkout'
      })
      if (!useAltPayment) {
        createCheckout(data)
      } else {
        await createAltCheckout(data)
      }
    }
  }
  const handleTermsCheck = () => {
    setTermsCheck(!termsCheck)
    setTermsError(false)
  }

  useEffect(() => {
    async function handleAsync() {
      let resp = await ParentAPI.getStudent({
        params: { studentID: data.studentID }
      })
      if (resp.success) {
        setStudent(resp.data)
      } else {
        alert('There was an error retrieving data for the checkout')
      }

      resp = await ParentAPI.getActivities({
        params: { activityIDs: data.activities }
      })

      if (resp.success) {
        setActivities(resp.data)
      } else {
        alert('There was an error retrieving data for the checkout')
      }
    }
    handleAsync()

    ReactGA.send({
      hitType: 'pageview',
      page: '/membership_confirm',
      title: 'Membership Confirm'
    })
    ReactPixel.pageView()
  }, [])

  let name
  if (subscription.name.includes('Membership-')) {
    //cleans up name string
    let tmp = subscription.name.split('-')
    name = tmp[1]
  } else {
    name = subscription.name
  }

  const days = Array.from(
    new Set(activities.map((activity) => activity.instance.day))
  ).sort((a, b) => CONSTANTS.SHORT_DAY_INDEX[a] - CONSTANTS.SHORT_DAY_INDEX[b])

  const orderDetails = () => {
    let DEFAULT_DETAILS = [
      {
        key: 'Student',
        value: `${student.info.name.fName} ${student.info.name.lName}`
      }
    ]

    let PAYMENT_DETAILS = []
    if (useAltPayment) {
      let paymentIDLabel
      if (data.alternatePaymentType == 'homeschool') {
        paymentIDLabel = 'PO Number'
      } else {
        paymentIDLabel = 'Check Number'
      }
      PAYMENT_DETAILS = [
        { key: 'Start Date', value: data.alternatePaymentDetails.startDate },
        { key: 'End Date', value: data.alternatePaymentDetails.endDate },
        { key: paymentIDLabel, value: data.alternatePaymentDetails.paymentID }
      ]
    } else {
      PAYMENT_DETAILS = [
        {
          key: 'Payment Plan',
          value: `$${Number(data.price.price).toLocaleString('en-US')} / ${
            data.price.period
          }`
        },
        {
          key: 'Start Date',
          value: `${
            data.startDate ? data.startDate : new Date().toDateString()
          }`
        }
      ]
    }

    let Line = (object) => {
      return (
        <React.Fragment key={object.key}>
          <Grid item xs={5}>
            <Typography variant='body1' align='left' fontWeight={'bold'}>
              {object.key}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant='body1' align='left'>
              {object.value}
            </Typography>
          </Grid>
        </React.Fragment>
      )
    }

    return (
      <Grid container sx={{ alignContent: 'flex-start', marginBottom: '20px' }}>
        {DEFAULT_DETAILS.map((object) => Line(object))}
        {PAYMENT_DETAILS.map((object) => Line(object))}
      </Grid>
    )
  }

  const getPlural = () => {
    if (parseInt(subscription.numOfDays) > 1) {
      return 'Days'
    } else {
      return 'Day'
    }
  }

  return (
    <>
      {student !== null && activities.length > 0 ? (
        <Box
          sx={{
            margin: '0px auto 200px auto',
            width: { xs: '90%', sm: '70%', md: '50%', lg: '40%' }
          }}
        >
          <BigCard icon={() => <></>} title='Order Details'>
            <Grid container>
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                  component='img'
                  alt='Subscription Icon'
                  sx={{ width: '100%' }}
                  src={require(`../../images/courseIcons/${
                    subscription.icon || 'robie.png'
                  }`)}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sx={{
                  textAlign: 'left',
                  paddingLeft: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start'
                }}
              >
                <Typography variant='h3' display='inline'>
                  {name}
                </Typography>
                <Typography
                  variant='subtitle2'
                  sx={{ fontSize: { xs: '14px', md: '18px' } }}
                >
                  {subscription.numOfDays} {getPlural()} per Week |{' '}
                  {subscription.locationName}
                </Typography>
              </Grid>
              {days.map((day, index) => {
                return (
                  <React.Fragment key={day}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        textAlign: 'left',
                        marginTop: index == 0 ? '20px' : '10px'
                      }}
                    >
                      <Typography variant='h5' color='primary'>
                        {CONSTANTS.DAYS_SHORT_TO_LONG[day]}
                      </Typography>
                    </Grid>
                    {activities.map((activity) => {
                      if (activity.instance.day == day) {
                        return (
                          <React.Fragment key={activity.course.name}>
                            <Grid item xs={5} sx={{ textAlign: 'left' }}>
                              <Typography display='inline' variant='h6'>
                                {activity.course.name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={7}
                              sx={{
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <Typography>
                                {dateHandler.toStandardTime(
                                  activity.instance.startTime
                                )}{' '}
                                -{' '}
                                {dateHandler.toStandardTime(
                                  activity.instance.endTime
                                )}
                              </Typography>
                            </Grid>{' '}
                          </React.Fragment>
                        )
                      }
                    })}
                  </React.Fragment>
                )
              })}
            </Grid>
            <Divider sx={{ margin: '20px 0px' }} />
            {orderDetails()}
            <Divider sx={{ margin: '20px 0px' }} />
            <Box sx={{ marginBottom: '20px', textAlign: 'left' }}>
              {useAltPayment ? (
                <>
                  <Typography variant='h6'>Next Steps</Typography>
                  <Typography>
                    After you submit your order, an admin will verify your
                    information. You can view the status of your verification on
                    your account info page. Once the information is verified,
                    your student will be able to enjoy their membership
                    benefits!
                  </Typography>
                </>
              ) : (
                <>
                  {/* {data.paymentType === 'card' ? (
                    <Typography
                      variant='caption'
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      A 3.5% processing fee will be added to this payment at
                      checkout
                    </Typography>
                  ) : (
                    <></>
                  )} */}
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end'
                }}
              >
                <Checkbox
                  checked={termsCheck}
                  onChange={(e) => handleTermsCheck()}
                  sx={{
                    '.MuiSvgIcon-root': { color: termsError ? 'red' : null }
                  }}
                />
                <Typography sx={{ fontSize: '11px', textAlign: 'left' }}>
                  By checking this box, you confirm that you have read and agree
                  to our{' '}
                  <b>
                    <a href='/terms#terms-and-conditions' target='_blank'>
                      terms and conditions
                    </a>
                  </b>
                  , as well as the
                  <b>
                    {' '}
                    <a href='/terms#waiver' target='_blank'>
                      liability waiver
                    </a>
                  </b>
                  .
                </Typography>
              </Box>
              {termsError ? (
                <Typography sx={{ fontSize: '11px', color: 'red' }}>
                  Please check above to agree to Terms & Conditions and
                  liability waiver.
                </Typography>
              ) : (
                <></>
              )}
            </Box>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={handleSubmit} variant='contained'>
                Place Order
              </Button>
            </div>
          </BigCard>
        </Box>
      ) : (
        <LoadingComp />
      )}
    </>
  )
}

export default ConfirmDetails
