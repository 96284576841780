import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import cards from '../util/cards'
import {
  Box,
  Button,
  Tabs,
  Tab,
  Typography,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Alert,
  Snackbar
} from '@mui/material'
import { EditorState } from 'draft-js'
import { convertToHTML } from 'draft-convert'
import { GetTemplates, updateTemplate } from '../../api/admin'
import TemplateEditor from './templateEditor'
import { sendTest } from '../../api/admin'
import LoadingComp from '../util/loading'


const EmailTemplates = (props) => {
  const BlankCard = cards.blankCard
  const [templateOptions, setTemplateOptions] = useState([])
  const [viewTemplate, setViewTemplate] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [loading, setLoading] = useState(true)
  const uID = useSelector((state) => state.login.user._id)
  const [showTest, setShowTest] = useState(false)
  const [testAddress, setTestAddress] = useState('')
  const [testConfirmation, setTestConfirmation] = useState(false)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )

  let adminEmail = useSelector((state) => state.login.user.userName)

  const [currTab, setCurrTab] = useState(0)

  //get templates from db
  useEffect(() => {
    async function handleAsync() {
      let resp = await GetTemplates()
      if (resp.status === 200) {
        setTemplateOptions(resp.data)
        setLoading(false)
      } else {
        alert('There was an error retrieving templates')
      }
    }
    handleAsync()
  }, [])

  const selectTemplateView = (data) => {
    setViewTemplate(data)
  }

  const handleTabChange = (e, newValue) => {
    setCurrTab(newValue)
  }

  const convertWithLinks = () => {
    //convert editor state into HTML with a href links
    const html = convertToHTML({
      blockToHTML: (block) => {
        if (block.type === 'PARAGRAPH') {
          return <p />
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>
        }
        return originalText
      }
    })(editorState.getCurrentContent())
    return html
  }

  const handleTemplateSave = async () => {
    //save template changes to database
    const html = convertWithLinks()
    let tmp = { ...viewTemplate, content: html }
    let resp = await updateTemplate({ ...tmp, uID: uID })

    if (resp.success) {
      setViewTemplate(tmp)
      let index = templateOptions.findIndex((option) => option._id == tmp._id)
      setTemplateOptions((prev) => {
        prev.splice(index, 1, tmp)
        return prev
      })
      setIsEdit(false)
    } else {
      alert('Error updating')
    }
  }

  const handleTemplateEdit = (type, value) => {
    setViewTemplate((prev) => {
      let tmp = { ...prev }
      tmp[type] = value
      return tmp
    })
  }

  const handleCloseTest = () => {
    setShowTest(false)
  }

  const handleCloseTestConfirmation = () => {
    setTestConfirmation(false)
  }

  const handleTestAddress = (value) => {
    setTestAddress(value)
  }

  const handleSendTest = async () => {
      let resp = await sendTest({
        uID: uID, //admin authorization
        emails: [testAddress], //email recipient
        //pre and post variables
        courseName: '[COURSE NAME]',
        location: '[LOCATION NAME]',
        time: '[TIME]',
        endDate: '[END DATE]',
        startDate: '[START DATE]',
        maxAge: '[MAX AGE]',
        minAge: '[MIN AGE]',
        emailType: viewTemplate._id,
        adminEmail: adminEmail,
        address: {
          lineOne: '[ADDRESS LINE ONE]',
          lineTwo: '[ADDRESS LINE TWO]',
          city: '[CITY]'
        },
        //sign up confirmation variables
        parent: {name: {
          fName: '[PARENT FIRST NAME]',
          lName: '[PARENT LAST NAME]'
        }},
        emailStr: '[CAMP DETAILS]',
        //student sign up variables
        sName: '[STUDENT NAME]',
        memName: '[MEMBERSHIP NAME]',
        courseString: '[MEMBERSHIP COURSES]',
        //membership change variables
        managerEmail: '[MANAGER EMAIL]',
        student: {
          fName: '[STUDENT FIRST NAME]'
        },
        membershipName: '[MEMBERSHIP NAME]'
      })
      if (resp.data.MessageId) {
        setShowTest(false)
        setTestConfirmation(true)
      } else {
        alert('Something went wrong. Please make sure the email address entered is valid.')
      }
  }

  const handleCancel = () => {
    let index = templateOptions.findIndex(
      (option) => option._id == viewTemplate._id
    )
    setViewTemplate(templateOptions[index])
    setIsEdit(false)
  }

  return (
    <>
      <Snackbar
        open={testConfirmation}
        autoHideDuration={6000}
        onClose={handleCloseTestConfirmation}
        message='Note archived'
      >
        <Alert>Test Email Sent</Alert>
      </Snackbar>
      <Dialog open={showTest} onClose={handleCloseTest}>
        <DialogTitle>Type address to send test email to</DialogTitle>
        <DialogContent>
          <TextField
            label='Email address'
            value={testAddress}
            onChange={(e) => handleTestAddress(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSendTest()}>Send</Button>
        </DialogActions>
      </Dialog>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '30% auto',
          width: '90%',
          margin: 'auto auto'
        }}
      >
        {loading ? (
          <>
            <LoadingComp />
          </>
        ) : (
          <>
            <BlankCard>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  margin: '0px 0px 30px 0px'
                }}
              >
                <Tabs
                  value={currTab}
                  onChange={handleTabChange}
                  aria-label='navigation tabs'
                >
                  <Tab label='Automated' key='Automated' />
                  {/* <Tab label='Custom' key='Custom' /> */}
                </Tabs>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {templateOptions.map((data) => {
                  let isSelected = false
                  if (viewTemplate?._id == data._id) {
                    isSelected = true
                  }
                  return (
                    <Box
                      sx={{
                        backgroundColor: isSelected ? 'secondary.main' : null,
                        color: isSelected ? '#fff' : null,
                        '&:hover': {
                          cursor: 'pointer',
                          backgroundColor: isSelected ? null : '#f5f5f5'
                        },
                        textAlign: 'left',
                        padding: '5px 5px'
                      }}
                      onClick={() => selectTemplateView(data)}
                      key={data._id}
                    >
                      {data.type}
                    </Box>
                  )
                })}
              </Box>
            </BlankCard>

            <BlankCard box={{ padding: '0px 0px' }}>
              {viewTemplate ? (
                <>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: 'divider',
                      // marginBottom: '20px',
                      position: 'relative',
                      padding: '20px 25px 10px 25px'
                    }}
                  >
                    <Typography variant='h2' align='left'>
                      {viewTemplate.type}
                    </Typography>
                    <Typography align='left'>{viewTemplate.details}</Typography>
                  </Box>

                  {isEdit ? (
                    <>
                      <Box sx={{ padding: '20px 25px 1px 25px' }}>
                        <TextField
                          fullWidth
                          id='template-subject'
                          label='Subject'
                          value={viewTemplate.subject}
                          onChange={(e) =>
                            handleTemplateEdit('subject', e.target.value)
                          }
                          sx={{ marginBottom: '10px' }}
                        />
                        <TemplateEditor
                          data={viewTemplate}
                          editorState={editorState}
                          setEditorState={setEditorState}
                          convertWithLinks={convertWithLinks}
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        sx={{
                          padding: '20px 25px 10px 25px',
                          borderBottom: 1,
                          borderColor: 'divider',
                          position: 'relative'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '10px',
                            position: 'absolute',
                            left: 25,
                            top: 5
                          }}
                        >
                          Subject
                        </Typography>
                        <Typography align='left' sx={{}}>
                          {viewTemplate.subject}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          padding: '20px 25px 10px 25px',
                          position: 'relative'
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '10px',
                            position: 'absolute',
                            left: 25,
                            top: 5
                          }}
                        >
                          Body
                        </Typography>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: viewTemplate.content
                          }}
                          style={{
                            minHeight: '40vh',
                            textAlign: 'left'
                          }}
                        ></div>
                      </Box>
                    </>
                  )}
                  <Box
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {isEdit ? (
                      <>
                        <Button onClick={() => handleCancel()}>Cancel</Button>

                        <Button onClick={() => handleTemplateSave()}>
                          Save
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={() => setShowTest(true)}>
                          Send Test
                        </Button>
                        <Button onClick={() => setIsEdit(true)}>Edit</Button>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <div>No template selected. Select a Template to View</div>
              )}
            </BlankCard>
          </>
        )}
      </Box>
    </>
  )
}

export default EmailTemplates
