import React, { useState, useEffect, useRef, forwardRef } from 'react'
import {
  Box,
  Typography,
  Button,
  Collapse,
  MobileStepper,
  IconButton,
  useMediaQuery,
  Slide
} from '@mui/material'
import Section from './section'
import MapSelector from '../../utils/mapSelector'
import { GetLocations } from '../../api/locations'
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material'
import listHandler from '../../utils/listHandler'

const LocationSection = ({ styles, type }) => {
  const [visibleLocations, setVisibleLocations] = useState([])
  const [collapsedLocations, setCollapsedLocations] = useState([])
  const [showCollapsed, setShowCollapsed] = useState(false)
  const [loading, setLoading] = useState(true)
  const locations = useRef([])
  const [activeStep, setActiveStep] = useState(0)
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let getLocations = await GetLocations()
      if (type == 'workshop') {
        locations.current = getLocations.data
          .filter((loc) => loc.workshopSeasons.length > 0)
          // TO DO: Refactor sorting
          //Sort by list priority, then alphabetize
          .sort((a, b) => {
            if (b.listPriority && !a.listPriority) {
              return 1
            } else if (a.listPriority && !b.listPriority) {
              return -1
            } else {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            }
          })
        //Alphabetize separate lists a second time.
        setVisibleLocations(
          listHandler.alphabetizeList(locations.current.slice(0, 4), 'name')
        )
        setCollapsedLocations(
          listHandler.alphabetizeList(
            locations.current.slice(4, locations.current.length),
            'name'
          )
        )
      } else {
        locations.current = getLocations.data.filter(
          (loc) => loc.memberships.length > 0
        )
      }

      setLoading(false)
    }
    if (mounted) {
      handleAsync()
    }
    return () => (mounted = false)
  }, [])

  const handleCollapsed = () => {
    setShowCollapsed(!showCollapsed)
  }
  const [direction, setDirection] = useState('next')
  const handleNext = () => {
    setDirection('next')
    if (activeStep === locations.current.length - 1) {
      setActiveStep(0)
    } else {
      setActiveStep((prev) => prev + 1)
    }
  }
  const handleBack = () => {
    setDirection('prev')
    if (activeStep === 0) {
      setActiveStep(locations.current.length - 1)
    } else {
      setActiveStep((prev) => prev - 1)
    }
  }

  const PARAGRAPH = '20px'

  const CampLocationCard = ({ location }) => {
    return (
      <Box
        key={location.name}
        sx={{
          backgroundColor: '#fff',
          padding: '20px 20px',
          borderRadius: '10px',
          width: { md: '280px' },
          zIndex: 10,
          display: 'grid',
          gridTemplateColumns: '40% auto',
          gridGap: '10px',
          textAlign: 'left',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => {
          window.open(`/summer-camps/?location=${location._id}`)
        }}
      >
        <Typography variant='h5' sx={{ gridColumn: '1/3' }}>
          {location.name}
        </Typography>
        <Box sx={{ gridColumn: { xs: '1/2', md: '1/3' } }}>
          <MapSelector location={location} width='100%' height='100%' />
        </Box>
        <Box sx={{ gridColumn: { xs: '2/3', md: '1/3' } }}>
          <Typography>{location.address.lineOne}</Typography>
          {location.address.lineTwo.length > 0 ? (
            <Typography>{location.address.lineTwo}</Typography>
          ) : null}
          <Typography>
            {location.address.city}, {location.address.state}{' '}
            {location.address.postal}
          </Typography>
        </Box>
      </Box>
    )
  }

  const MembershipLocationCard = forwardRef(({ location, addStyles }, ref) => {
    let county = 'Los Angeles County'
    if (parseInt(location.address.postal) >= 92620) {
      county = 'Orange County'
    }

    let urlParam = location.name.toLowerCase().replaceAll(' ', '-')
    return (
      <Box
        ref={ref}
        key={location.name}
        sx={{
          ...addStyles, //additional styles for mobile
          backgroundColor: '#fff',
          padding: '20px 20px',
          borderRadius: '10px',
          width: { xs: '85%', md: '280px', lg: '250px' },
          display: 'grid',
          margin: 'auto auto',
          gridTemplateColumns: '100%',
          gridGap: '10px',
          textAlign: 'left',
          zIndex: 10
        }}
      >
        <Box>
          <Typography
            sx={{
              color: 'gray',
              fontSize: '14px',
              fontWeight: 'bold'
            }}
          >
            {county}
          </Typography>
          <Typography variant='h5' sx={{}}>
            {location.name}
          </Typography>
        </Box>
        <Box sx={{}}>
          <MapSelector location={location} width='100%' height='100%' />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Button
            onClick={() => {
              window.open(`/memberships/${urlParam}`)
            }}
            sx={{ color: '#fff' }}
          >
            View Available Memberships
          </Button>
        </Box>
      </Box>
    )
  })

  const LocationGrid = (props) => {
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '100%',
            md: 'repeat(2, auto)',
            lg: 'repeat(4, auto)'
          },
          gridGap: '20px',
          justifyContent: 'center'
        }}
      >
        {props.children}
      </Box>
    )
  }

  const ref = useRef(null)

  return (
    <Section
      title={'Locations'}
      sx={{
        backgroundColor: styles.lightBlue,
        position: 'relative',
        minHeight: mobile ? '100vh' : '60vh'
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', md: '60%' },
          margin: 'auto auto',
          marginBottom: '50px'
        }}
      >
        {mobile ? null : (
          <>
            <Box
              sx={{
                backgroundImage: `url(${require('../../images/fbLandingImages/Asset_39.png')})`,
                backgroundSize: '300px auto',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '0px 200px',
                width: '300px',
                height: '100%',
                bottom: 0,
                position: 'absolute',
                left: 0,
                zIndex: 0
              }}
            />
            <Box
              sx={{
                backgroundImage: `url(${require('../../images/fbLandingImages/Asset_38.png')})`,
                backgroundSize: { xs: '200px auto', md: '400px auto' },
                width: { xs: '200px', md: '400px' },
                height: '100%',
                top: 0,
                position: 'absolute',
                right: 0,
                zIndex: 0
              }}
            />
          </>
        )}
        <Typography
          textAlign='center'
          variant='h5'
          sx={{ marginBottom: PARAGRAPH, zIndex: 10, position: 'relative' }}
        >
          Explore the Exciting Locations for Rolling Robots{' '}
          {type == 'workshop' ? 'Summer Camps' : 'Programs'}
        </Typography>
        <Typography
          textAlign='center'
          sx={{ marginBottom: PARAGRAPH, zIndex: 10, position: 'relative' }}
        >
          Click on any of the places listed below to get the precise location.
        </Typography>
        <Typography
          textAlign='center'
          sx={{ marginBottom: PARAGRAPH, zIndex: 10, position: 'relative' }}
        >
          You can be confident that your child's adventure into the world of
          technology and innovation is only a few clicks away thanks to the
          thoughtful placement of our camps, which guarantees accessibility and
          convenience for everyone.
        </Typography>
      </Box>
      <Box sx={{ ...styles.box, margin: 'auto auto' }}>
        {!loading ? (
          type == 'workshop' ? (
            <>
              <LocationGrid>
                {visibleLocations.map((location) => {
                  return (
                    <CampLocationCard location={location} key={location._id} />
                  )
                })}
              </LocationGrid>
              <Collapse in={showCollapsed} sx={{ marginTop: '20px' }}>
                <LocationGrid>
                  {collapsedLocations.map((location) => {
                    return (
                      <CampLocationCard
                        location={location}
                        key={location._id}
                      />
                    )
                  })}
                </LocationGrid>
              </Collapse>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '30px 0px'
                }}
              >
                <Button variant='contained' onClick={() => handleCollapsed()}>
                  {showCollapsed ? 'Show Less Locations' : 'See All Locations'}
                </Button>
              </Box>
            </>
          ) : (
            <>
              {mobile ? (
                <>
                  <Box
                    sx={{
                      display: 'grid',
                      position: 'relative'
                    }}
                  >
                    {locations.current.map((location, index) => {
                      const slideDirection =
                        (direction == 'next' && activeStep == index) ||
                        (direction == 'prev' && activeStep !== index)
                      return (
                        <Slide
                          unmountOnExit
                          mountOnEnter
                          direction={slideDirection ? 'left' : 'right'}
                          in={activeStep == index}
                          sx={{ flexGrow: 1 }}
                        >
                          <MembershipLocationCard
                            addStyles={{ gridRow: '1/2', gridColumn: '1/2' }}
                            ref={ref}
                            location={location}
                            key={location._id}
                            index={index}
                          />
                        </Slide>
                      )
                    })}
                  </Box>
                  <MobileStepper
                    variant='dots'
                    steps={locations.current.length}
                    activeStep={activeStep}
                    position='relative'
                    sx={{
                      maxWidth: 400,
                      flexGrow: 1,
                      background: 'rgba(0, 0, 0, 0)',
                      margin: 'auto auto'
                    }}
                    nextButton={
                      <IconButton size='small' onClick={() => handleNext()}>
                        <KeyboardArrowRight />
                      </IconButton>
                    }
                    backButton={
                      <IconButton size='small' onClick={() => handleBack()}>
                        <KeyboardArrowLeft />
                      </IconButton>
                    }
                  />
                </>
              ) : (
                <LocationGrid>
                  {locations.current.map((location) => {
                    return (
                      <MembershipLocationCard
                        location={location}
                        key={location._id}
                      />
                    )
                  })}
                </LocationGrid>
              )}
            </>
          )
        ) : (
          'Loading...'
        )}
      </Box>
    </Section>
  )
}

export default LocationSection
