import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  IconButton,
  Box,
  TextField,
  InputAdornment,
  Typography
} from '@mui/material'
import LoadingComp from '../util/loading'
import cards from '../util/cards'
import { getCustomers, searchCustomers } from '../../api/admin'
import { setSelectedUser } from '../../store/userSlice'
import { useNavigate } from 'react-router-dom'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

const UserTable = ({ type }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  let user = useSelector((state) => state.login.user)
  const selectedUser = useSelector((state) => state.user.selectedUser)

  const SmallCard = cards.smallCard

  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [count, setCount] = useState(0)
  const [disableBack, setDisableBack] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [searched, setSearched] = useState(false)

  const getUsers = async () => {
    setLoading(true)
    let resp = await getCustomers({ uID: user._id, type: type, count: count })
    if (resp.data) {
      setUsers(resp.data.users)
      setLoading(false)
    } else {
      alert('There was an error getting users')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (count > 0) {
      setDisableBack(false)
    } else {
      setDisableBack(true)
    }

    getUsers()
  }, [count])

  const handlePageChange = (direction) => {
    if (direction === 'back') {
      setCount(count - 10)
    } else {
      setCount(count + 10)
    }
  }

  const handleSearch = async () => {
    setSearched(true)
    let resp = await searchCustomers({
      params: { searchValue: searchValue, uID: user._id, type: type }
    })
    if (resp.success) {
      setUsers(resp.data.users)
    } else {
      alert('There was an error searching for customers')
    }
  }

  const changeSearchValue = (value) => {
    setSearchValue(value)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleReset = async () => {
    setSearched(false)
    setSearchValue('')
    setCount(0)
    getUsers()
  }

  const handleUserSelect = (row) => {
    let parentID = null
    if (type === 1) {
      parentID = row._id
    } else {
      parentID = row.parents[0]
    }
    /**If parent is not the same as previously selected, reset selectedUser.
     * If parent is the same, this will speed up the website by preventing an API call
     * on the account page
     */
    if (selectedUser?._id !== parentID) {
      dispatch(setSelectedUser(null))
    }
    navigate(`/account-view/${parentID}`)
  }

  return (
    <>
      <Box>
        <TextField
          fullWidth
          autoFocus
          id='search-user'
          label='Search'
          value={searchValue}
          placeholder={type === 1 ? 'Name, Email, or Phone' : 'Name'}
          onChange={(e) => {
            changeSearchValue(e.target.value)
          }}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    handleSearch()
                  }}
                  edge='end'
                  disabled={searchValue === ''}
                >
                  <SearchIcon />
                </IconButton>
                {searched ? (
                  <IconButton
                    onClick={() => {
                      handleReset()
                    }}
                    edge='end'
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            )
          }}
        />
        {type === 1 ? (
          <div style={{ width: '100%', textAlign: 'left' }}>
            Format phone number (###)###-####
          </div>
        ) : (
          <></>
        )}
      </Box>

      {searched ? (
        <></>
      ) : (
        <div style={{ marginTop: '5px' }}>
          <IconButton
            aria-label='orders-back'
            size='small'
            disabled={disableBack}
            onClick={() => handlePageChange('back')}
          >
            {<ArrowBackIcon />}
          </IconButton>
          <IconButton
            aria-label='orders-forward'
            size='small'
            onClick={() => handlePageChange('forward')}
          >
            {<ArrowForwardIcon />}
          </IconButton>
        </div>
      )}

      {!loading > 0 ? (
        <div style={{ marginTop: '10px' }}>
          {users.length > 0 ? (
            <>
              {users.map((row) => {
                const addProps = {
                  onClick: () => handleUserSelect(row)
                }

                return (
                  <React.Fragment key={`${row.userName}_${row._id}`}>
                    <SmallCard
                      addProps={addProps}
                      title={`${row.info.name.fName} ${row.info.name.lName}`}
                      subtitleOne={`${row.userName}`}
                    ></SmallCard>
                  </React.Fragment>
                )
              })}
            </>
          ) : (
            <Typography variant='h5'>No users match the search</Typography>
          )}
        </div>
      ) : (
        <LoadingComp />
      )}
    </>
  )
}

export default UserTable
