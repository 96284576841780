import { useEffect, useState } from 'react'
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import listHandler from '../../../utils/listHandler'

const LocationSelector = ({ expanded }) => {
  const navigate = useNavigate()
  const params = useParams()

  const locations = useSelector((state) => state.user.locations)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)

  const handleLocationChange = (event) => {
    navigate(`/admin-memberships/${event.target.value}`, { replace: true })
  }
  const [locationOptions, setLocationOptions] = useState([])
  useEffect(() => {

    async function handleAsync() {
      if (defaultRegion._id) {
        //If user has selected a location in a region, reset for viewing a different region
        if (params.locationID !== 'all') {
          navigate('/admin-memberships/all', { replace: true })
        }
        let regionLocations = locations
        if (defaultRegion.name !== 'All') {
          regionLocations = locations.filter(
            (loc) => loc.region == defaultRegion._id
          )
        }
        setLocationOptions(listHandler.alphabetizeList(regionLocations, 'name'))
      } else {
        setLocationOptions(listHandler.alphabetizeList(locations, 'name'))
      }
    }
    if (locations.length > 0) {
      handleAsync()
    }
  }, [defaultRegion, locations])

  //Automatically set compressed view to Palos Verdes
  useEffect(() => {
    if (!expanded) {
      // navigate(`/admin-memberships/${locations[0]._id}`, { replace: true })
      navigate('/admin-memberships/all', { replace: true })

    }
  }, [expanded])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: '95%'
      }}
    >
      <FormControl>
        <InputLabel id='locatio-select-label'>Location</InputLabel>
        <Select
          labelId='location-select-label'
          id='location-select'
          value={params.locationID || 'all'}
          label='Location'
          name='Location Selection'
          onChange={(event) => {
            handleLocationChange(event)
          }}
          style={{ minWidth: '200px' }}
        >
          <MenuItem value={'all'} key='All'>
            All
          </MenuItem>
          {locationOptions.length > 0 ? (
            locationOptions.map((location) => (
              <MenuItem value={location._id} key={location._id}>
                {location.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>Loading...</MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  )
}

export default LocationSelector
