import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Typography,
  Checkbox
} from '@mui/material'
import {
  setAltPaymentDetails,
  setAltPaymentType,
  setUseAltPayment
} from '../../store/cartSlice'
import AlternatePaymentToolTip from '../alternatePaymentToolTip'
import cards from '../util/cards'

const StudentSelect = ({
  source,
  children,
  selectedChild,
  handleChildSelect
}) => {
  const dispatch = useDispatch()
  let isSignedIn = useSelector((state) => state.login.isLoggedIn)
  const useAltPayment = useSelector((state) => state.cart.useAltPayment)

  //Checks if user is admin or parent
  let parent = useSelector((state) => {
    if (
      state.login.user.permissions &&
      state.login?.user?.permissions[0] === 4
    ) {
      return state.user.selectedUser
    } else {
      return state.login.user
    }
  })

  const BlankCard = cards.blankCard

  const handleShowAltPayOptions = () => {
    if (useAltPayment) {
      dispatch(setAltPaymentType(''))
      dispatch(setAltPaymentDetails({}))
      dispatch(setUseAltPayment(false))
    } else {
      dispatch(setUseAltPayment(true))
    }
  }

  return (
    <>
      {isSignedIn ? (
        <>
          <Typography variant={'h4'}>
            {source === 'invite' ? 'Child:' : '1. Select a Child'}
          </Typography>
          <BlankCard>
            <FormControl fullWidth>
              <InputLabel>Select a child</InputLabel>
              <Select
                value={selectedChild}
                id='Child'
                label='Select a Child'
                //error={errorChecker('child')}
                style={{ minWidth: '100px' }}
                onChange={handleChildSelect}
                disabled={source === 'invite'}
              >
                {children.map((child) => {
                  return (
                    <MenuItem value={child} key={child['_id']}>
                      {child.info.name.fName == 'Add Roboteer'
                        ? child.info.name.fName
                        : child.info.name.fName + ' ' + child.info.name.lName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            {parent.creditAuthorized ? (
              <>
                <FormControl>
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '-10px'
                    }}
                  >
                    <Checkbox
                      checked={useAltPayment}
                      onChange={() => handleShowAltPayOptions()}
                    />
                    <Typography display='inline'>
                      Enroll with alternate payment
                    </Typography>
                    <AlternatePaymentToolTip />
                  </span>
                </FormControl>
              </>
            ) : null}
          </BlankCard>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default StudentSelect
