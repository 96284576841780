import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography, Button } from '@mui/material'
import Section from './section'
const BlogSection = ({ styles }) => {
  const PARAGRAPH = '20px'

  const BLOG_CONTENT = [
    {
      image: 'Asset_17.png',
      category: 'Student Achievements',
      date: 'Jan 17, 2023',
      title: 'InventTeam 2022-23 - Building The Smart Walker',
      description:
        'Rolling Robots InvenTeam aims to solve a problem in our community',
      link: 'https://rollingrobotsonline.com/inventteam-2022-23-building-smart-walker'
    },
    {
      image: 'Asset_16.png',
      category: 'Competition Robotics',
      date: 'May 31, 2022',
      title: 'TWO DIVISION CHAMPIONS AT 2022 VEX WORLDS',
      description: 'Rolling Robots Competition Teams have reached new heights',
      link: 'https://rollingrobotsonline.com/two-division-champions-2022-vex-worlds'
    },
    {
      image: 'Asset_15.png',
      category: 'Competition Robotics',
      date: 'Apr 04, 2021',
      title: 'Rolling Robots VEX IQ Teams Win Big At State Championships',
      description:
        'Meet four Award winning Elementary school Robotics teams who qualified for the VEX IQ World Championship',
      link: 'https://rollingrobotsonline.com/rolling-robots-vex-iq-teams-win-big-state-championships'
    }
  ]

  return (
    <Section
      title={'Empowering Future Innovators with Proven Results'}
      sx={{ backgroundColor: styles.lightGrey }}
      capitalCase
    >
      <Box
        sx={{
          width: { xs: '90%', md: '60%' },
          margin: 'auto auto',
          textAlign: 'center'
        }}
      >
        <Typography sx={{ marginBottom: PARAGRAPH }}>
          See how Rolling Robots motivates kids to succeed in the tech world.
          The amazing things our students have done and their success in VEX
          Robotics competitions show how engaging and effective our teaching
          methods are.
        </Typography>
        <Typography sx={{ marginBottom: PARAGRAPH }}>
          You're giving your kid a special chance to join the ranks of
          accomplished young innovators by registering them for one of our
          summer camps.
        </Typography>
        <Typography sx={{ marginBottom: '50px' }}>
          See the inspiring pictures below to see what our gifted kids have
          accomplished and to get an idea of the amazing potential your child
          can achieve with Rolling Robots. We'll inspire the upcoming generation
          of trailblazers together!
        </Typography>
      </Box>
      <Box sx={{ ...styles.box }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '100%',
              md: 'minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)'
            },
            gridGap: '30px',
            marginBottom: '50px'
          }}
        >
          {BLOG_CONTENT.map((blogPost) => {
            return (
              <Box key={blogPost.title}>
                <Box
                  sx={{
                    backgroundImage: `url(${require(`../../images/fbLandingImages/${blogPost.image}`)})`,
                    width: '100%',
                    height: '250px',
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom',
                    WebkitBoxShadow: `inset 0 0 0 6px ${styles.red}`,
                    boxShadow: `inset 0 0 0 6px ${styles.red}`,
                    borderRadius: '20px',
                    marginBottom: '20px'
                  }}
                />
                <Typography
                  sx={{
                    marginBottom: '5px',
                    fontSize: '0.9rem',
                    color: 'gray'
                  }}
                >
                  {blogPost.category} - {blogPost.date}
                </Typography>
                <Typography
                  sx={{ marginBottom: '5px', fontSize: '1rem' }}
                  fontWeight='bold'
                >
                  {blogPost.title}
                </Typography>
                <Typography
                  sx={{
                    marginBottom: '15px',
                    fontSize: '0.9rem',
                    color: 'gray'
                  }}
                >
                  {blogPost.description}
                </Typography>
                <Typography
                  fontWeight='bold'
                  onClick={() => window.open(blogPost.link)}
                  sx={{ '&:hover': { cursor: 'pointer' } }}
                >
                  + Read More
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Section>
  )
}

export default BlogSection
