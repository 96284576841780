import { useSelector } from 'react-redux'
import { useState } from 'react'
import {
  Typography,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip,
  Box,
  Card,
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import dateHandler from '../../utils/dateHandler'

const ActivitySelect = ({
  activities,
  selectedActivities,
  handleActivitySelect,
  checkNumOfDays
}) => {
  const [expanded, setExpanded] = useState(null)

  let isSignedIn = useSelector((state) => state.login.isLoggedIn)
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )

  const isSelected = (activityID) => {
    if (selectedActivities.includes(activityID)) {
      return true
    } else {
      return false
    }
  }
  const getCapacity = (activity) => {
    let cap = parseInt(activity.instance.capacity)
    let current = activity.instance.students.length
    return cap - current
  }

  return (
    <>
      {activities.length === 0 ? (
        <h1>There are no activities for this subscription</h1>
      ) : (
        <>
          <Typography variant={'h4'} sx={{ marginTop: '-10px' }}>
            {isSignedIn
              ? `2. Select ${subscription.numOfDays} Activit${
                  subscription.numOfDays > 1 ? 'ies' : 'y'
                }`
              : 'Activities'}
          </Typography>
          {activities.map((activity, i) => {
            let space = getCapacity(activity)
            let checked = isSelected(activity.instance._id)
            let panel = 'panel' + i
            return (
              <div>
              <Card>
                <Grid
                  container
                  flexDirection="row"
                  sx={12} md={12}
                  marginTop={'10px'}
                  marginLeft={'10px'}
                >
                  <Box>
                    {isSignedIn ? (
                      <Checkbox
                        onChange={(e) => {
                          handleActivitySelect(activity.instance._id)
                        }}
                        checked={checked}
                        disabled={(!checked && checkNumOfDays()) || space <= 0}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Grid item xs={9} sm={10}>
                    <Typography variant='h3' color={'primary'}>
                      {activity.course.name}
                    </Typography>
                    <Grid item xs={12}>
                    <Typography
                        fontWeight={'bold'}
                      >
                        {activity.instance.day}{' '}
                        {dateHandler.toStandardTime(
                          activity.instance.startTime
                        )}{' '}
                        -{' '}
                        {dateHandler.toStandardTime(activity.instance.endTime)}
                      </Typography>
                      <Typography>
                        Ages {activity.course.age.min} -{' '}
                        {activity.course.age.max}
                      </Typography>

                      <Accordion
                        elevation={0}
                        disableGutters                      
                        key={activity.instance._id}
                        id={activity.course.name}
                        expanded={expanded == panel}
                        onChange={(e) => {
                          if (e.target.tagName !== 'INPUT') {
                            //Do not expand if clicking checkbox
                            if (expanded == panel) {
                              setExpanded(null)
                            } else {
                              setExpanded(panel)
                            }
                          }
                        }}
                      >
                      <Grid
                        item
                        sx={{ maxWidth:'105px'}} 
                      >
                      <AccordionSummary 
                          expandIcon = { expanded == panel ? (<ExpandMore sx={{ marginTop: '20px' }}/>):(<ExpandMore sx={{ marginTop: '-20px' }}/>)}> 
                        <Typography sx={{ marginTop: '-20px' }} >
                          Description
                        </Typography>
                      </AccordionSummary>
                    </Grid>
                    <AccordionDetails sx={{marginLeft: '-16.5px', marginTop:'-10px'}}>
                      {activity.course.description
                        ? activity.course.description
                        : 'Description coming soon!'}
                    </AccordionDetails>
                    </Accordion>
                    </Grid>
                    {space <= 2 ? (
                      <Grid item>
                        <Chip
                          sx={{
                            backgroundColor: 'primary.light',
                            color: '#fff',
                            fontWeight: 'bold'
                          }}
                          label={
                            space > 0
                              ? `${space} spot${space > 1 ? 's' : ''} left!`
                              : 'Full'
                          }
                        />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid> 
                </Grid>
              </Card>
                  
              <br></br>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

export default ActivitySelect
