import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Typography, Grid, Box, useMediaQuery } from '@mui/material'
import { createCheckout, checkEnrollment } from '../../api/subscription'
import ParentAPI from '../../api/parentApi'
import cards from '../util/cards'
import { useNavigate, useLocation } from 'react-router-dom'
import { setCheckoutData } from '../../store/subscriptionSlice'
import ModelHandler from '../../models/modelHandler'
import generateEmail from '../../utils/generateFakeEmail'
import { createUser } from '../../api/userApi'
import { addStudent } from '../../store/parentSlice'
import { setUseAltPayment } from '../../store/cartSlice'
import StudentForm from '../studentForm'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import getNameString from '../../utils/nameString'
import ActivitySelect from './activitySelect'
import PaymentPlanSelect from './paymentPlanSelect'
import AltPaymentSelect from './altPaymentSelect'
import StudentSelect from './studentSelect'
import DateSelect from './dateSelect'
import { getCreditsByID } from '../../api/credit'
import PaymentTypeSelect from './paymentTypeSelect'

const SelectDetails = ({
  setCurrTab,
  childName,
  source,
  activities,
  children,
  setChildren,
  purchaseByAdmin
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const windowLocation = useLocation()

  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const selectedPrice = useSelector((state) => state.subscription.selectedPrice)
  //Checks if user is admin or parent
  let parent = useSelector((state) => {
    if (
      state.login.user.permissions &&
      state.login?.user?.permissions[0] === 4
    ) {
      return state.user.selectedUser
    } else {
      return state.login.user
    }
  })
  const uID = useSelector((state) => state.login.user._id)
  let isSignedIn = useSelector((state) => state.login.isLoggedIn)

  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  const [selectedChild, setSelectedChild] = useState('')
  const [selectedActivities, setSelectedActivities] = useState([])
  const [dateError, setDateError] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  //Alternate Payments Logic
  const useAltPayment = useSelector((state) => state.cart.useAltPayment)
  const altPaymentType = useSelector((state) => state.cart.altPaymentType)
  const altPaymentDetails = useSelector((state) => state.cart.altPaymentDetails)
  const note = useSelector((state) => state.cart.note)
  const amount = useSelector((state) => state.cart.amount)
  const startDate = useSelector((state) => state.cart.startDate)
  const dateConfirmed = useSelector((state) => state.cart.dateConfirmed)
  //StudentForm logic
  const [showStudentForm, setShowStudentForm] = useState(false)
  const [newChild, setNewChild] = useState({})

  //Payment type logic
  const [paymentType, setPaymentType] = useState('card')

  const SmallCard = cards.smallCard

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/membership_details',
      title: 'Membership Details'
    })
    ReactPixel.pageView()
  }, [subscription])

  useEffect(() => {
    if (purchaseByAdmin) {
      dispatch(setUseAltPayment(true))
    }
  }, [purchaseByAdmin])

  useEffect(() => {
    if (source === 'invite' && children.length > 0) {
      let selection = children.filter(
        (child) => child.info.name.fName === childName
      )
      setSelectedChild(selection[0])
    }
  }, [children])

  const checkNumOfDays = () => {
    if (selectedActivities.length >= subscription.numOfDays) {
      return true
    } else {
      return false
    }
  }

  const handleActivitySelect = (activityID) => {
    let tmpActivityArr = [...selectedActivities]
    let index = tmpActivityArr.indexOf(activityID)

    if (index === -1) {
      setSelectedActivities([...selectedActivities, activityID])
    } else {
      tmpActivityArr.splice(index, 1)
      setSelectedActivities([...tmpActivityArr])
    }
  }

  const handleChildSelect = (event) => {
    if (event.target.value._id === 'create') {
      setShowStudentForm(true)
    } else {
      setSelectedChild(event.target.value)
    }
  }

  let enrollMsg

  if (!isSignedIn && subscription.requiresInvite) {
    enrollMsg = 'Sign in to request invite'
  } else if (!isSignedIn && !subscription.requiresInvite) {
    enrollMsg = 'Sign In to Enroll'
  } else if (isSignedIn && subscription.requiresInvite) {
    enrollMsg = 'Request Invite'
  } else {
    enrollMsg = 'Proceed to check out'
  }

  const handleDeleteInvite = async (invite) => {
    let resp = await ParentAPI.deleteInvite({
      studentID: selectedChild._id,
      subscriptionID: subscription._id
    })
    if (resp.success) {
      window.history.pushState({}, 'Rolling Robots', '/')
      window.location.reload()
    } else {
      alert('There was a problem deleting your invite. Please try again')
      //setOpen(false)
    }
  }
  useEffect(() => {
    if (dateError) {
      if (alternatePaymentCheck()) {
        setDateError(false)
      }
    }
  }, [altPaymentDetails])

  const alternatePaymentCheck = () => {
    //checks that the alternate payment start date and end date are valid
    if (altPaymentDetails.startDate && altPaymentDetails.endDate) {
      let startDateObj = new Date(
        `${altPaymentDetails.startDate}T12:00:00.000Z`
      )
      let endDateObj = new Date(`${altPaymentDetails.endDate}T12:00:00.000Z`)
      if (startDateObj >= endDateObj) {
        return false
      } else {
        return true
      }
    }
  }

  const changePaymentType = (value) => {
    setPaymentType(value)
  }

  const handleSubmit = async () => {
    if (isSignedIn) {
      //Determine if the submit is invite request or a checkout
      if (
        (source === 'invite' && subscription.requiresInvite) ||
        !subscription.requiresInvite
      ) {
        let check = await checkEnrollment({
          params: {
            subscriptionID: subscription._id,
            studentID: selectedChild._id
          }
        })
        let proceed = true //Proceed will be set to false if an error occurs
        if (check.success) {
          if (check.data.isEnrolled) {
            if (useAltPayment) {
              let resp = await getCreditsByID({
                uID: uID,
                studentID: selectedChild._id,
                documentID: subscription._id
              })
              if (resp.success) {
                let currentEnrollmentEnd =
                  resp.data[resp.data.length - 1].endDate
                if (
                  new Date(altPaymentDetails.startDate) <
                  new Date(currentEnrollmentEnd)
                ) {
                  alert(
                    `${selectedChild.info.name.fName} is already enrolled in this subscription. Please select a start date that is after ${currentEnrollmentEnd}`
                  )
                  proceed = false
                }
              } else {
                proceed = false
                alert(resp.error)
              }
            } else {
              proceed = false
              alert(
                `${selectedChild.info.name.fName} is already enrolled in this subscription`
              )
            }
          }
          if (proceed) {
            if (useAltPayment && !alternatePaymentCheck()) {
              setDateError(true)
            } else {
              let data = {
                alternatePaymentType: altPaymentType,
                alternatePaymentDetails: altPaymentDetails,
                price: selectedPrice,
                locationID: subscription.locationID,
                parentID: selectedChild?.parents[0],
                studentID: selectedChild._id,
                activities: selectedActivities,
                username: parent.userName,
                stripeID: parent.paymentMeta.stripe.stripeID,
                subscriptionID: subscription._id,
                note: note,
                isAdmin: parent.permissions[0] === 4,
                amount: amount,
                startDate: startDate,
                paymentType: paymentType
              }
              if (source === 'catalog') {
                dispatch(setCheckoutData(data))
                setCurrTab(3)
              } else {
                createCheckout(data)
              }
            }
          }
        } else {
          alert('There was a problem with the order')
        }
      } else {
        let data = {
          subscription: subscription,
          parent: parent,
          student: selectedChild,
          activities: selectedActivities
        }
        let resp = await ParentAPI.requestSubInvite(data)
        if (resp.success) {
          setShowSuccess(true)
        } else {
          alert('There was a problem requesting the invitation')
        }
      }
    } else {
      let redirectString = `${windowLocation.pathname}${windowLocation.search}`
      navigate('/login', { state: { redirectTo: redirectString } })
    }
  }

  const Icon = () => {
    return (
      <Box
        component='img'
        alt='Subscription Icon'
        sx={{ width: '100px', padding: '10px 10px', marginRight: '10px' }}
        src={require(`../../images/courseIcons/${
          subscription.icon || 'robie.png'
        }`)}
      />
    )
  }

  const handleNewUser = (type, value) => {
    setNewChild((prevState) => ({
      ...prevState,
      [type]: value
    }))
  }

  const handleNextChildForm = async () => {
    var tmp = newChild
    tmp.parentID = parent._id
    tmp.addrCheck = true // temp work around for not adding extra fields
    if (tmp.addrCheck === undefined || tmp.addrCheck === true) {
      tmp.address = parent.info.address
    }
    let err = ModelHandler.checkStudentData(tmp, true)
    if (err.length === 0) {
      if (!('email' in tmp) || tmp.email === '') {
        tmp.email = generateEmail(tmp)
      }
      let data = ModelHandler.student(tmp)
      let resp = await createUser(data)

      setNewChild(resp)
      setShowStudentForm(false)
      dispatch(addStudent(resp.data))
      setChildren((prevState) => [...prevState, resp.data])
      setSelectedChild(resp.data)
    }
  }

  const FIELDS = {
    homeschool: [
      { label: 'Start Date', type: 'date', id: 'startDate' },
      { label: 'End Date', type: 'date', id: 'endDate' },
      { label: 'PO Number', type: 'text', id: 'paymentID' }
    ],
    check: [
      { label: 'Start Date', type: 'date', id: 'startDate' },
      { label: 'Payment Plan', type: null, id: 'plan' },
      { label: 'Iterations', type: 'number', id: 'iterations' },
      { label: 'Check Number', type: 'text', id: 'paymentID' }
    ]
  }

  const checkEnrollmentFields = () => {
    let check = true

    if (!checkNumOfDays() || typeof selectedChild == 'string') {
      check = false
    }

    if (useAltPayment) {
      if (altPaymentType === '') {
        check = false
      } else if (altPaymentType.includes('homeschool')) {
        FIELDS['homeschool'].forEach((field) => {
          //Submission does not need the plan, but the user still needs to fill it out
          if (!altPaymentDetails[field.id] && field.id !== 'plan') {
            check = false
          }
        })
      } else {
        FIELDS[altPaymentType].forEach((field) => {
          //Submission does not need the plan, but the user still needs to fill it out
          if (!altPaymentDetails[field.id] && field.id !== 'plan') {
            check = false
          }
        })
      }
    } else {
      if (
        startDate === null ||
        selectedPrice === null ||
        !isSignedIn ||
        !dateConfirmed
      ) {
        check = false
      }
    }
    return check
  }

  return (
    <>
      {showSuccess ? (
        <>
          <Typography variant='h6'>Invite Requested</Typography>
          <Button
            variant='contained'
            onClick={() => {
              setCurrTab(0)
            }}
          >
            Back
          </Button>
        </>
      ) : (
        <>
          <Grid
            container
            sx={{
              padding: {xs: '0 0 5% 0', md: '0 10% 5% 10%'},
              minWidth: '400px',
              marginBottom: '200px'
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'left', padding: '10px 10px' }}
            >
              <SmallCard
                icon={Icon}
                title={getNameString(subscription)}
                subtitleOne={`${subscription.numOfDays} Days per Week`}
              >
                {subscription.description ? (
                  <Typography variant='body1'>
                    {subscription.description}
                  </Typography>
                ) : null}
              </SmallCard>
              <StudentSelect
                source={source}
                children={children}
                selectedChild={selectedChild}
                handleChildSelect={handleChildSelect}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ textAlign: 'left', padding: '10px 10px' }}
            >
              <ActivitySelect
                activities={activities}
                selectedActivities={selectedActivities}
                handleActivitySelect={handleActivitySelect}
                checkNumOfDays={checkNumOfDays}
              />

              {useAltPayment ? (
                <AltPaymentSelect FIELDS={FIELDS} dateError={dateError} />
              ) : (
                <>
                  <DateSelect />
                  <PaymentPlanSelect />
                  <PaymentTypeSelect
                    paymentType={paymentType}
                    handleChange={changePaymentType}
                  />
                </>
              )}

              {source === 'invite' ? (
                <Grid
                  item
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button
                    onClick={() => {
                      handleDeleteInvite()
                    }}
                  >
                    Delete Invite
                  </Button>
                  {checkNumOfDays() &&
                  typeof selectedChild !== 'string' &&
                  selectedPrice !== null &&
                  startDate !== null ? (
                    <Button variant='contained' onClick={() => handleSubmit()}>
                      {enrollMsg}
                    </Button>
                  ) : (
                    <></>
                  )}
                </Grid>
              ) : (
                <Grid item>
                  {isSignedIn ? (
                    <>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Button
                          disabled={!checkEnrollmentFields()}
                          variant='contained'
                          onClick={() => handleSubmit()}
                        >
                          {enrollMsg}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>

          {showStudentForm ? (
            <StudentForm
              data={newChild}
              handleChange={handleNewUser}
              handleNext={handleNextChildForm}
              handleClose={() => {
                setShowStudentForm(false)
              }}
            />
          ) : (
            <React.Fragment />
          )}
        </>
      )}
    </>
  )
}

export default SelectDetails
