import React, { useEffect, useState } from 'react'
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Box,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton
} from '@mui/material'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import '../index.css'
import LoadingComp from './util/loading'
import { setYear } from '../store/workshopSlice'
import CONSTANTS from '../constants'
import EditIcon from '@mui/icons-material/Edit'
import SeasonEdit from './seasonEdit'
import { updateWorkshopSeasons } from '../api/admin'
import MapSelector from '../utils/mapSelector'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import { setLocations } from '../store/userSlice'
import { setRegions } from '../store/adminSlice'
import { ExpandMore } from '@mui/icons-material'
import { getRegions } from '../api/locations'
import listHandler from '../utils/listHandler'

const LocationSelector = ({
  locations,
  onSelect,
  type,
  season,
  permissions,
  toggleCustomer
}) => {
  //used to select location to view location specific workshops and memberships
  const dispatch = useDispatch()
  const regions = useSelector((state) => state.admin.regions)
  const defaultRegion = useSelector((state) => state.login.defaultRegion)
  let year = useSelector((state) => state.workshop.year)
  const uID = useSelector((state) => state.login.user._id)
  const [yearList, setYearList] = useState([])
  const [seasonEdit, setSeasonEdit] = useState({})

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/wkshp_location_select',
      title: 'Wkshp Location Select'
    })
    ReactPixel.pageView()

    //set current year to 3 months ahead for setting up future workshops
    let date = new Date()
    let month = date.getMonth() + 3
    if (month > 12) {
      month = month - 12
      date.setYear(date.getFullYear() + 1)
    }
    date.setMonth(month)
    let tmpYear = date.getFullYear()

    //set year list for dropdown menu
    let tmpYearList = [2022]
    let firstYear = tmpYearList[0]
    while (firstYear < tmpYear) {
      firstYear++
      tmpYearList.push(firstYear)
    }
    setYearList(tmpYearList)
    dispatch(setYear(tmpYear))
  }, [])

  const handleSubmit = async (values) => {
    let resp = await updateWorkshopSeasons({
      uID: uID,
      locationID: seasonEdit._id,
      workshopSeasons: values
    })
    if (resp.success) {
      let tmpLocations = [...locations]
      tmpLocations.splice(
        locations.findIndex(function (i) {
          return i._id === resp.data._id
        }),
        1,
        resp.data
      )
      dispatch(setLocations(tmpLocations))
      setSeasonEdit({})
    } else {
      alert(resp)
    }
  }

  const styles = {
    parent: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      columnGap: '5px',
      marginTop: '0%'
    },
    button: {
      backgroundColor: '#C80F2D',
      color: '#FFF',
      width: '60%',
      marginLeft: '20%',
      marginRight: '20%',
      marginBottom: '10px'
    }
  }

  const handleYearChange = (event) => {
    dispatch(setYear(event.target.value))
  }

  const handleSeasonClose = () => {
    setSeasonEdit({})
  }

  let alphabetizedLocations = listHandler.alphabetizeList(locations, 'name')

  // Sort locations by region
  // Admins with a region selected will only see locations in their region
  const [sortedLocations, setSortedLocations] = useState({})
  useEffect(() => {
    let isMounted = true
    async function handleAsync() {
      let tmpRegions
      if (regions.length === 0) {
        let resp = await getRegions()
        if (resp.data) {
          dispatch(setRegions(resp.data))
          tmpRegions = resp.data
        }
      } else {
        tmpRegions = regions
      }
      let tmpLocations = {}
      if (permissions >= 3 && toggleCustomer === 'Admin' && defaultRegion._id) {
        let adminLocations = []
        if (defaultRegion.name === 'All') {
          adminLocations = [...alphabetizedLocations]
        } else {
          adminLocations = alphabetizedLocations.filter(
            (loc) => loc.region === defaultRegion._id
          )
        }

        tmpLocations[defaultRegion['name']] = adminLocations
      } else {
        let noRegionSelected = alphabetizedLocations.filter(
          (loc) =>
            !loc.region &&
            (type === 'memberships' || loc.workshopSeasons.includes(season))
        )
        if (noRegionSelected.length > 0) {
          tmpLocations['none'] = noRegionSelected
        }
        tmpRegions.forEach((region) => {
          let filteredLocations = alphabetizedLocations.filter(
            (loc) =>
              loc.region === region._id &&
              (type === 'memberships' || loc.workshopSeasons.includes(season))
          )
          if (filteredLocations.length > 0) {
            tmpLocations[region.name] = filteredLocations
          }
        })
      }
      if (isMounted) {
        setSortedLocations(tmpLocations)
      }
    }
    handleAsync()
    return () => {
      isMounted = false
    }
  }, [locations, season, defaultRegion])

  const LocationCard = ({ location }) => {
    const [expand, setExpand] = useState(false)

    const toggleAccordion = () => {
      setExpand((prev) => !prev)
    }
    const [hover, setHover] = useState(null)
    return (
      <Paper
        key={location.name}
        sx={{
          backgroundColor: '#fff',
          width: { xs: '100%', md: '280px' },
          zIndex: 10,
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={(e) => {
          if (
            e.target.tagName === 'BUTTON' ||
            e.target.tagName === 'svg' ||
            e.target.tagName === 'path'
          ) {
            toggleAccordion()
          } else {
            onSelect(location)
          }
        }}
      >
        <Accordion
          expanded={
            permissions >= 3 && toggleCustomer === 'Admin' ? expand : true
          }
          sx={{
            '& .MuiAccordionSummary-root': {minHeight: '0px'},
        '& .MuiAccordionDetails-root': {paddingTop: '0px'}
      }}
        >
          <AccordionSummary
            expandIcon={
              permissions >= 3 && toggleCustomer === 'Admin' ? (
                <IconButton>
                  <ExpandMore />
                </IconButton>
              ) : (
                <></>
              )
            }
            sx={{'& .MuiAccordionSummary-root': {backgroundColor: 'green'}}}
            id={location.name + ' accordion'}
          >
            <Typography variant='h5'>{location.name}</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: 'grid',
              // gridTemplateColumns: '40% auto',
              gridGap: '10px',
              textAlign: 'left'
            }}
          >
            {/* <Box sx={{ gridColumn: { xs: '1/2', md: '1/3' } }}>
              <MapSelector location={location} width='100%' height='100%' />
            </Box> */}
            <Box 
            // sx={{ gridColumn: { xs: '2/3', md: '1/3' } }}
            >
              <Typography>{location.address.lineOne}</Typography>
              {location.address.lineTwo.length > 0 ? (
                <Typography>{location.address.lineTwo}</Typography>
              ) : null}
              <Typography>
                {location.address.city}, {location.address.state}{' '}
                {location.address.postal}
              </Typography>
              <Typography>{location.phone}</Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
        {permissions === 4 && toggleCustomer === 'Admin' ? (
          <Box
            onMouseEnter={() => setHover(location)} //controls whether or not season edit option is visible
            onMouseLeave={() => setHover(null)}
            sx={{
              gridColumn: { xs: '1/3' },
              margin: '0px auto',
              position: 'relative',
              width: '250px',
              display: 'flex',
              justifyContent: 'space-around',
              padding: '4px 4px',
              borderRadius: '5px 5px',
              '&:hover': {
                backgroundColor: 'divider'
              }
            }}
          >
            {hover?._id === location?._id ? (
              <Button
                variant='contained'
                disableElevation
                sx={{
                  position: 'absolute',
                  right: '5px',
                  minWidth: '0px',
                  padding: '5px 5px'
                }}
                onClick={() => setSeasonEdit(location)} //opens edit season modal
              >
                <EditIcon fontSize='15px' />
              </Button>
            ) : null}
            {CONSTANTS.SEASONS.map((season) => {
              let selected = false
              if (location.workshopSeasons.includes(season)) {
                selected = true
              }
              return (
                <Chip
                  key={`${location.name} ${season}`}
                  label={season}
                  sx={{
                    backgroundColor: selected ? '#3596ce' : null,
                    color: selected ? '#fff' : null
                  }}
                />
              )
            })}
          </Box>
        ) : null}
      </Paper>
    )
  }
  const Category = ({ category }) => {
    return (
      <>
        {/* If a location does not have a region selected, do not show a category title */}
        {category !== 'none' ? (
          <Grid item xs={12}>
            <Typography variant='h6' sx={{ textAlign: 'left' }}>
              {category}
            </Typography>
          </Grid>
        ) : null}
        {sortedLocations[category].map((location) => {
          return (
            <React.Fragment key={location.name}>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    marginBottom: '20px'
                    // minHeight: '250px'
                  }}
                >
                  <LocationCard location={location} />
                </Box>
              </Grid>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  const Title = () => {
    return (
      <Grid item xs={12}>
        <Typography
          variant='h3'
          sx={{
            marginTop: { xs: '30px', md: '15px' },
            textAlign: 'left'
          }}
        >
          Select Location to View{' '}
          {type === 'memberships' ? 'Available Memberships' : 'Camp Sessions'}
        </Typography>
      </Grid>
    )
  }
  const LocationCategories = () => {
    //Check if this season has any live locations
    if (Object.values(sortedLocations).flat().length > 0) {
      /**
       * If a user has set a default region, will only show locations from that region
       * If not, will show all regions
       */
      if (defaultRegion.name && defaultRegion.name !== 'All') {
        if (Object.keys(sortedLocations).includes(defaultRegion.name)) {
          return (
            <>
              <Title />
              <Category category={defaultRegion.name} />
            </>
          )
        } else {
          return (
            <Typography variant='h3'>
              Currently, there are no{' '}
              {type === 'memberships' ? 'memberships' : 'camps'} available for
              this region. Please select a different region or check back at a
              later date for updates!
            </Typography>
          )
        }
      } else {
        return (
          <>
            <Title />
            {Object.keys(sortedLocations).map((category) => {
              return (
                <React.Fragment key={category}>
                  <Category category={category} />
                </React.Fragment>
              )
            })}
          </>
        )
      }
    } else {
      return (
        <Typography variant='h3'>
          Currently, there are no camps available for this season. Please select
          a different season or check back at a later date for updates!
        </Typography>
      )
    }
  }

  return (
    <>
      {Object.keys(seasonEdit).length > 0 ? (
        //pop up modal to edit which workshop seasons are live
        <SeasonEdit
          handleClose={handleSeasonClose}
          selectedSeasons={seasonEdit.workshopSeasons}
          handleSubmit={handleSubmit}
        />
      ) : null}
      {alphabetizedLocations.length === 0 ? (
        <LoadingComp />
      ) : (
        <div style={styles.parent}>
          {permissions >= 3 && toggleCustomer === 'Admin' ? (
            <FormControl sx={{ width: 200, margin: '5px auto' }}>
              <InputLabel>Year</InputLabel>
              <Select label='Year' value={year} onChange={handleYearChange}>
                {yearList.map((yearOption) => {
                  return (
                    <MenuItem key={yearOption} value={yearOption}>
                      {yearOption}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          ) : (
            <></>
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {permissions >= 3 && toggleCustomer === 'Admin' ? (
                <Typography sx={{ textAlign: 'left' }}>
                  Note for Admins: If you have a region selected, you will only
                  see locations for that region below. But, the customer view
                  will show all locations that are live for that season.
                </Typography>
              ) : null}
            </Grid>
            <LocationCategories />
          </Grid>
        </div>
      )}
    </>
  )
}

export default LocationSelector
