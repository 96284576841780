import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, Checkbox } from '@mui/material'
import cards from '../util/cards'
import getMonthlyRate from '../../utils/monthlyRate'
import { setSelectedPrice } from '../../store/subscriptionSlice'

const PaymentPlanSelect = () => {
  const dispatch = useDispatch()
  const subscription = useSelector(
    (state) => state.subscription.selectedSubscription
  )
  const selectedPrice = useSelector((state) => state.subscription.selectedPrice)
  let isSignedIn = useSelector((state) => state.login.isLoggedIn)

  /**The default price is stored in the database as a string.
   * This variable helps to convert the default price to an object
   * so it can be rendered in this component
   */
  const [defaultPrice, setDefaultPrice] = useState({
    id: '',
    price: '',
    period: ''
  })

  //Converts prices that are rendered in cents to dollars
  let subscriptionPrice = subscription.price
  if (subscription.price >= 1000) {
    subscriptionPrice = subscriptionPrice / 100
  }

  const BlankCard = cards.blankCard

  /**Converts default price to object or automatically sets the selected price
   * (which is sent to the back end) during checkout. If there are no alternate prices
   * then the selected price will automatically be the default price.*/
  useEffect(() => {
    if (subscription) {
      let priceObj = {
        id: subscription.priceID,
        price: subscriptionPrice,
        period: 'month'
      }
      if (subscription.altPrices?.length > 0) {
        setDefaultPrice(priceObj)
      } else {
        dispatch(setSelectedPrice(priceObj))
      }
    }
  }, [subscription])

  const priceSelect = (price) => {
    const handlePriceSelect = () => {
      dispatch(setSelectedPrice(price))
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {isSignedIn ? (
          <Checkbox
            onChange={() => handlePriceSelect()}
            checked={price.id === selectedPrice?.id}
          />
        ) : (
          <></>
        )}
        <Typography>
          {`$${Number(price.price).toLocaleString('en-US')}`} per {price.period}
        </Typography>
        {price.period !== 'month' ? (
          <Typography variant='caption' style={{ marginLeft: '5px' }}>
            (equals ${getMonthlyRate(price)} per month)
          </Typography>
        ) : (
          <></>
        )}
      </div>
    )
  }

  return (
    <>
      {subscription.altPrices?.length === 0 ? (
        <>
          <Typography variant={'h4'} sx={{ paddingTop: '10px' }}>
            4. Payment Plan
          </Typography>
          <BlankCard>
            <Typography variant='h6'>${subscriptionPrice} per month</Typography>
          </BlankCard>
        </>
      ) : (
        <>
          <Typography variant={'h4'} sx={{ paddingTop: '10px' }}>
            {isSignedIn ? '4. Select Payment Plan' : '4. Payment Plans'}
          </Typography>
          <BlankCard>
            {priceSelect(defaultPrice)}
            {subscription.altPrices.map((altPrice) => {
              return (
                <React.Fragment key={altPrice.id}>
                  {priceSelect(altPrice)}
                </React.Fragment>
              )
            })}
          </BlankCard>
        </>
      )}
    </>
  )
}

export default PaymentPlanSelect
