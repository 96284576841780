import React, { useState, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material'
import { GetLocations } from '../api/locations'
import PhotoStepper from '../component/util/photoStepper'
import { ExpandMore } from '@mui/icons-material'
import Section from '../component/landing/section'
import SummerCampChart from '../component/landing/summerCampChart'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import ReactPixel from 'react-facebook-pixel'
import YoutubePlayer from '../component/youtubePlayer'
import LocationSection from '../component/landing/locationSection'
import BlogSection from '../component/landing/blogSection'
import STYLES from '../component/landing/landingStyles'
import TextStepper from '../component/util/textStepper'

const FBlandingPage = () => {
  const locations = useRef([])
  const [visibleLocations, setVisibleLocations] = useState([])

  useEffect(() => {
    async function handleAsync() {
      let getLocations = await GetLocations()
      locations.current = getLocations.data.filter(
        (loc) => loc.workshopSeasons.length > 0
      )
      setVisibleLocations(locations.current.slice(0, 4))
    }
    handleAsync()

    ReactGA.send({
      hitType: 'pageview',
      page: '/fblanding',
      title: 'fblanding'
    })
    ReactPixel.pageView()
  }, [])

  let PARAGRAPH = '20px'
  const mobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))

  let styles = STYLES

  const LEARNING_CONTENT = [
    {
      image: 'Asset_35.png',
      title: 'Robotics',
      detailsOne: ['Toys', 'LEGO', 'Battle Bots'],
      detailsTwo: ['VEX GO', 'IQ', 'V5', 'Dash']
    },
    {
      image: 'Asset_37.png',
      title: 'Coding',
      detailsOne: ['Scratch', 'Python', 'AI'],
      detailsTwo: ['Unity', 'C#', 'C++']
    },
    {
      image: 'Asset_36.png',
      title: 'Design',
      details: 'CAD, 3D Design, Minecraft, MOD, Build Robots',
      detailsOne: ['Fusion 360', 'onshape', '3D Printing'],
      detailsTwo: ['Minecraft', 'MOD', 'Roblox']
    }
  ]

  const AGE_GROUPS = [
    {
      title: 'Age 5 - 8',
      color: styles.yellow,
      courses: [
        'Jr. Robot Builder',
        'Super Car Build & Code',
        'Coding for Beginners'
      ]
    },
    {
      title: 'Age 7 - 11',
      color: styles.green,
      courses: [
        'My Robot Collections',
        'LEGO Spike',
        'Minecraft Build & Design'
      ]
    },
    {
      title: 'Age 8 - 12',
      color: styles.blue,
      courses: [
        'Minecraft Modding',
        '3D Printing Design',
        'Robot VEX IQ Build and Code',
        'Battle Bots Building',
        'Code in Python',
        'Unity Game Design - C#'
      ]
    },
    {
      title: 'Age 10 - 15',
      color: styles.purple,
      courses: [
        'FLL - Spike Robot',
        'Roblox Game Design & Code',
        'C++ Coding Circuits & Sensors',
        'VEX V5 Team Boot Camp (Full Day)',
        'C++ Coding for VEX Robots',
        'CAD for Robotics Competition'
      ]
    }
  ]

  const INSTRUCTORS = [
    {
      image: 'Instructors/Profile_Pictures-02.png',
      name: 'Sebastian',
      position: 'Instructor',
      details:
        'Sebastian Nunez is a Clark Magnet High School Alum, where he acquired a wide variety of computer aided design skills'
    },
    {
      image: 'Instructors/Profile_Pictures-12.png',
      name: 'Jeimy (pronounced jay-me)',
      position: 'Instructor/Glendale Manager',
      details:
        'Jeimy Portillo is passionate about teaching and advocating for STEM Education'
    },
    {
      image: 'Instructors/Profile_Pictures-11.png',
      name: 'Cameron',
      position: 'Instructor',
      details:
        "I have been teaching at Rolling Robots since 2017 and I am currently pursuing a Bachelor's in Computer Engineering"
    },
    {
      image: 'Instructors/Profile_Pictures-07.png',
      name: 'Enrique',
      position: 'Instructor',
      details:
        'Enrique began his programming journey almost 10 years ago when he began modding for Minecraft'
    }
  ]

  let TESTIMONIALS = [
    {
      name: 'E C.',
      subtitle: 'Glendale',
      details:
        "Amazing things can happen when like-minded kids get together and are guided by passionate and caring teachers! This is a true Maker Space, where it's chaotic and energetic, and where the kids feel completely comfortable and free to create and build and make a mess."
    },
    {
      name: 'Brandon W.',
      subtitle: 'Palos Verdes',
      details:
        'My 8-yr old son has attended the summer robotics camp for the past two years. He absolutely enjoys his time branching out and exploring into the world of Robotics. I recommend Rolling Robots to all young minds!'
    },
    {
      name: 'Danielle S.',
      subtitle: 'Los Angeles',
      details:
        'My seven year old son loves Rolling Robots! He goes to almost every Minecraft night and he looks forward to it all week. He likes that he gets to socialize with other Minecraft players and also with the employees who are super friendly and patient.'
    },
    {
      name: 'Talia P.',
      subtitle: 'Los Angeles',
      details:
        " If you are looking for coding classes, you will be very happy with the curriculum at Rolling Robots. My son looks forward to his classes, and when I pick him up he's never ready to go home, completely captivated with his work."
    }
  ]

  const handleVisibleLocations = () => {
    if (visibleLocations.length <= 4) {
      setVisibleLocations(locations.current)
    } else {
      setVisibleLocations(locations.current.slice(0, 4))
    }
  }

  window.onload = () => {
    const loader = document.querySelector('.loader')
    loader.classList.add('loader--hide')
    loader.style.setProperty('content', 'none')
  }

  let siteURL =
    process.env.REACT_APP_MODE === 'DEVELOP'
      ? 'http://localhost:3000/'
      : 'https://www.rollingrobots.net/'

  return (
    <>
      <Section
        id='intro-video'
        sx={{
          backgroundColor: styles.yellow,
          minHeight: { xs: '55vh', lg: '30vh' },
          position: 'relative'
        }}
      >
          <picture>
            <source
              type='image/webp'
              srcset={require('../images/fbLandingImages/Asset_34.webp')}
              media='(min-width:300px)'
            />
            <Box
              component='img'
              alt='red-gears-left'
              src={require('../images/fbLandingImages/Asset_34.png')}
              sx={{
                height: { xs: '280px', md: '28vw' },
                bottom: { xs: '38%', md: 0 },
                position: 'absolute',
                left: 0
              }}
            />
          </picture>
        <picture>
          <source
            type='image/webp'
            srcset={require('../images/fbLandingImages/Asset_9.webp')}
            media='(min-width:300px)'
          />
          <Box
            component='img'
            alt='red-gears-right'
            src={require('../images/fbLandingImages/Asset_9.png')}
            sx={{
              minHeight: { md: '250px' },
              height: { xs: '340px', md: '30vw' },
              position: 'absolute',
              top: { xs: '15%', md: '10%' },
              right: { xs: '-100px', md: 0 }
            }}
          />
        </picture>
        <Box
          sx={{
            ...styles.box,
            display: 'grid',
            gridTemplateColumns: '55% auto',
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          <Typography
            variant='h1'
            sx={{
              marginBottom: PARAGRAPH,
              gridColumn: { xs: '1/3', md: '1/2' },
              alignSelf: 'end'
            }}
          >
            Want to Help Your Kids Learn Robotics and Coding and Have Fun at the
            Same Time?
          </Typography>
          <Box
            sx={{
              gridColumn: { xs: '1/3', md: '2/3' },
              gridRow: { xs: null, md: '1/3' },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <YoutubePlayer playerImage={'fbLandingImages/Asset_8.png'} />
          </Box>
          <Box
            sx={{
              gridColumn: { xs: '1/3', md: '1/2' },
              marginRight: { md: '50px' }
            }}
          >
            <Typography
              sx={{
                marginBottom: '10px'
              }}
            >
              If so, here’s how Rolling Robots is giving kids aged 5-15 the
              tools and skills they need to ignite their passion for robotics
              and Stem education.
            </Typography>
            <Typography
              sx={{
                fontStyle: 'italic',
                fontWeight: 'bold',
                marginBottom: PARAGRAPH
              }}
            >
              Hands-on fun & learning your kids will love at Rolling Robots
              summer camps.
            </Typography>
            <Button
              onClick={() => {
                window.location.pathname = '/enroll'
              }}
              variant='contained'
            >
              Enroll Now
            </Button>
          </Box>
        </Box>
      </Section>
      <Section
        tagline={'Embark on your Adventure to Tech Genius'}
        title={'Summer Camps'}
        sx={{ minHeight: '60vh', backgroundColor: styles.lightGrey }}
      >
        <Box sx={{ ...styles.box }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '50% auto',
              gridGap: '30px',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Box sx={{ gridColumn: { xs: '1/3', md: '1/2' } }}>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                It's simple for families to choose the ideal spring or summer
                camp nearby thanks to these 20+ accessible spots spread around
                Southern California.
              </Typography>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                Every young mind eager to study tech, robotics, and coding can
                find something to enjoy among the over 20 engaging STEM and
                robotics camps available.
              </Typography>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                To ensure that every child has the chance to realize their full
                potential, our programs welcome young innovators from the age of
                5 to 15.
              </Typography>
              <Typography sx={{ marginBottom: PARAGRAPH }}>
                Bring a lifelong enthusiasm for study and exploration in the
                field of technology to Rolling Robots this summer!
              </Typography>
            </Box>
            <picture>
              <source
                type='image/webp'
                srcset={require('../images/fbLandingImages/Asset_3.webp')}
                media='(min-width:300px)'
              />
              <Box
                sx={{
                  backgroundImage: `url(${require('../images/fbLandingImages/Asset_3.png')})`,
                  backgroundSize: 'cover',
                  WebkitBoxShadow: `inset 0 0 0 6px ${styles.yellow}`,
                  boxShadow: `inset 0 0 0 6px ${styles.yellow}`,
                  borderRadius: '20px',
                  minHeight: '400px',
                  gridRowStart: { xs: 1, md: null },
                  gridColumn: { xs: '1/3', md: '2/3' }
                }}
              />
            </picture>
          </Box>
          <Box
            sx={{
              width: mobile ? '100%' : '50%',
              display: 'flex',
              justifyContent: 'center',
              margin: '40px auto'
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                window.location.pathname = '/summer-camps'
              }}
            >
              Summer Camps
            </Button>
          </Box>
        </Box>
      </Section>
      <Section
        id='testimonials'
        sx={{
          backgroundColor: styles.lightBlue,
          padding: '50px 0px 20px 0px',
          display: 'flex',
          flexDirection: 'justify-content'
        }}
      >
        <Box sx={{ ...styles.box, textAlign: 'center' }}>
          <TextStepper yellow={true} textOptions={TESTIMONIALS} />
        </Box>
      </Section>
      <Section
        id='learning-content'
        title={'What Your Kids Will Be Learning'}
        capitalCase
        sx={{ minHeight: null }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'auto',
              md: 'repeat(3, minmax(0, 1fr))'
            },
            ...styles.box,
            marginBottom: '50px',
            gridGap: '30px'
          }}
        >
          {LEARNING_CONTENT.map((item) => {
            return (
              <Box sx={{ textAlign: 'center' }} key={item.title}>
                <picture>
                  <source
                    type='image/webp'
                    srcset={require(`../images/fbLandingImages/${item.image}`)}
                    media='(min-width:300px)'
                  />
                  <Box
                    component='img'
                    sx={{
                      backgroundImage: `url(${require(`../images/fbLandingImages/${item.image}`)})`,
                      height: '200px',
                      width: '100%',
                      maxWidth: '300px',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      WebkitBoxShadow: `inset 0 0 0 6px ${styles.red}`,
                      boxShadow: `inset 0 0 0 6px ${styles.red}`,
                      borderRadius: '20px'
                    }}
                  />
                </picture>
                <Typography
                  // variant='h5'
                  sx={{
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    fontSize: '26px'
                  }}
                >
                  {item.title}:
                </Typography>

                <Typography sx={{ fontSize: '18px' }}>
                  {item.detailsOne.map((detail, index) => (
                    <span key={detail}>
                      {detail}
                      {index < item.detailsOne.length - 1 ? ' - ' : null}
                    </span>
                  ))}
                </Typography>
                <Typography sx={{ fontSize: '18px' }}>
                  {item.detailsTwo.map((detail, index) => (
                    <span key={detail}>
                      {detail}
                      {index < item.detailsTwo.length - 1 ? ' - ' : null}
                    </span>
                  ))}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Section>

      <Section
        title={'Rolling Robots Summer Camps'}
        sx={{ backgroundColor: styles.lightGrey }}
      >
        <Box sx={{ ...styles.box, width: { xs: '95%', sm: '90%', md: '80%' } }}>
          <SummerCampChart
            mobile={mobile}
            styles={styles}
            smallScreen={smallScreen}
            largeScreen={largeScreen}
          />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '100%', sm: '25% 25% 25% 25%' },
              textAlign: 'center',
              gridGap: { sm: '5px' }
            }}
          >
            {AGE_GROUPS.map((ageGroup) => {
              if (mobile) {
                return (
                  <Accordion key={ageGroup.title}>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      sx={{ backgroundColor: ageGroup.color }}
                    >
                      <Typography variant='h5'>{ageGroup.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {ageGroup.courses.map((course) => {
                        return (
                          <Typography sx={{ padding: '10px 0px' }} key={course}>
                            {course}
                          </Typography>
                        )
                      })}
                    </AccordionDetails>
                  </Accordion>
                )
              } else {
                return (
                  <Box key={ageGroup.title}>
                    <Typography
                      variant='h5'
                      sx={{
                        padding: '10px 10px',
                        backgroundColor: ageGroup.color
                      }}
                    >
                      {ageGroup.title}
                    </Typography>
                    {ageGroup.courses.map((course) => {
                      return (
                        <Typography sx={{ padding: '10px 0px' }} key={course}>
                          {course}
                        </Typography>
                      )
                    })}
                  </Box>
                )
              }
            })}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '30px 0px'
            }}
          >
            <Button
              variant='contained'
              onClick={() => {
                window.location.pathname = '/summer-camps'
              }}
            >
              Sign Up Now
            </Button>
          </Box>
        </Box>
      </Section>

      <LocationSection styles={styles} mobile={mobile} type='workshop' />
      <Section title={'The Rolling Robots Way'}>
        <Box
          sx={{
            ...styles.box,
            display: 'grid',
            gridTemplateColumns: { xs: 'auto', md: '50% auto' },
            height: '100%',
            marginBottom: '50px',
            gridGap: '20px'
          }}
        >
          <Box
            sx={{ position: 'relative', gridColumn: { xs: null, md: '2/3' } }}
          >
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_33.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                src={require(`../images/fbLandingImages/Asset_33.png`)}
                sx={{ width: '100%' }}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_29.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                alt='yellow-gear'
                sx={{
                  position: 'absolute',
                  left: '-4%',
                  bottom: '0%',
                  height: '15%'
                }}
                src={require(`../images/fbLandingImages/Asset_29.png`)}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_24.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                alt='yellow-zig-zag'
                sx={{
                  position: 'absolute',
                  right: '0%',
                  top: '0%',
                  height: '15%'
                }}
                src={require(`../images/fbLandingImages/Asset_24.png`)}
              />
            </picture>
          </Box>
          <Box
            sx={{
              gridColumn: { xs: null, md: '1/2' },
              gridRow: { md: '1/2' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Typography sx={{ marginBottom: PARAGRAPH }}>
              Experience the Rolling Robots Way, where we nurture young minds
              and ignite their creativity through a unique blend of learning and
              fun!
            </Typography>
            <Typography sx={{ marginBottom: PARAGRAPH }}>
              Our courses are like PhD programs for kids, designed to challenge
              them intellectually while keeping them entertained. With small
              group settings, we ensure each child receives the personalized
              attention they deserve.
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_25.webp`)}
                media='(min-width:300px)'
              />
              <Box
                alt='red-zigzag'
                component='img'
                src={require(`../images/fbLandingImages/Asset_25.png`)}
                sx={{
                  position: 'absolute',
                  height: '8%',
                  top: smallScreen ? '-12%' : '-18%',
                  left: smallScreen ? null : '-15%',
                  right: smallScreen ? '-10%' : null
                }}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_32.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                src={require(`../images/fbLandingImages/Asset_32.png`)}
                sx={{ width: '100%' }}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_23.webp`)}
                media='(min-width:300px)'
              />
              <Box
                alt='red-circle'
                component='img'
                src={require(`../images/fbLandingImages/Asset_23.png`)}
                sx={{
                  position: 'absolute',
                  height: '30%',
                  top: '15%',
                  left: '-10%'
                }}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_22.webp`)}
                media='(min-width:300px)'
              />
              <Box
                alt='yellow-circle'
                component='img'
                src={require(`../images/fbLandingImages/Asset_22.png`)}
                sx={{
                  position: 'absolute',
                  height: '15%',
                  top: '38%',
                  left: '-10%'
                }}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_28.webp`)}
                media='(min-width:300px)'
              />
              <Box
                alt='yellow-triangle'
                component='img'
                src={require(`../images/fbLandingImages/Asset_28.png`)}
                sx={{
                  position: 'absolute',
                  height: '5%',
                  bottom: smallScreen ? '-5%' : '-15%',
                  left: '30%'
                }}
              />
            </picture>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Typography sx={{ marginBottom: PARAGRAPH }}>
              Kids build their own robots and learn to program them using code.
              They explore new coding logic, build different structures, and a
              whole lot more!
            </Typography>
            <Typography sx={{ marginBottom: PARAGRAPH }}>
              We give kids a purpose to work with their hands and heads,
              fostering their problem-solving skills and passion for innovation.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'left' }
            }}
          >
            <Typography sx={{ marginBottom: PARAGRAPH }}>
              For over a decade, we have been creating friendly and welcoming
              environments where kids can thrive. Join our summer camps,
              inventor workshops, courses, and membership programs at Rolling
              Robots to learn in a fun, hands-on way!
            </Typography>
          </Box>
          <Box sx={{ position: 'relative' }}>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_31.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                src={require(`../images/fbLandingImages/Asset_31.png`)}
                sx={{ width: '100%' }}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_29.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                alt='yellow-gear'
                sx={{
                  position: 'absolute',
                  right: '-13%',
                  top: '30%',
                  height: '30%'
                }}
                src={require(`../images/fbLandingImages/Asset_29.png`)}
              />
            </picture>
            <picture>
              <source
                type='image/webp'
                srcset={require(`../images/fbLandingImages/Asset_28.webp`)}
                media='(min-width:300px)'
              />
              <Box
                component='img'
                alt='yellow-triangle'
                sx={{
                  position: 'absolute',
                  left: smallScreen ? '10%' : '-20%',
                  bottom: '-10%',
                  height: '10%'
                }}
                src={require(`../images/fbLandingImages/Asset_28.png`)}
              />
            </picture>
          </Box>
        </Box>
      </Section>

      <BlogSection styles={styles} />
      <Section
        sx={{ backgroundColor: styles.yellow }}
        title={<>Meet Our {mobile ? <br /> : null}Expert Instructors</>}
      >
        <Box sx={{ ...styles.box, textAlign: 'center' }}>
          <Typography
            variant='h3'
            sx={{ marginBottom: PARAGRAPH, fontStyle: 'italics' }}
          >
            Presenting Rolling Robots' Instructors
          </Typography>
          <Typography sx={{ marginBottom: PARAGRAPH }}>
            See some of our featured instructors' photos below to get to know
            them better. You will discover more about these knowledgeable tech
            educators who are driven to impart their knowledge and inspire a
            love of technology in the next generation with just one click.
          </Typography>
          <Typography sx={{ marginBottom: PARAGRAPH }}>
            Our instructors come from many different places, and each one has a
            unique set of skills and experiences that help create a lively
            learning environment. Join Rolling Robots to give your kid a chance
            to learn from experts in the field as they set out to become future
            innovators.
          </Typography>
          <PhotoStepper photos={INSTRUCTORS} />
        </Box>
      </Section>
      {/* <Section
        title={<>Meet Some of {mobile ? <br /> : null} Our Happy Students</>}
        sx={{ backgroundColor: styles.lightBlue }}
      >
        <Box sx={{ ...styles.box, textAlign: 'center' }}>
          <Typography
            sx={{
              marginBottom: PARAGRAPH,
              fontStyle: 'italic',
              fontWeight: 'bold'
            }}
          >
            Find out about the inspiring things that Rolling Robots students
            have done.
          </Typography>
          <Typography sx={{ marginBottom: PARAGRAPH }}>
            Take a moment and click on the pictures of the students below to
            learn more about their experiences.
          </Typography>
          <Typography sx={{ marginBottom: PARAGRAPH }}>
            Learn about their amazing times on our courses and summer camps,
            where they not only improved their tech skills but also made
            memories that will last a lifetime. These smart young people are
            great examples of the passion, creativity, and hard work that
            Rolling Robots tries to instil in all of its students.
          </Typography>
          <Typography sx={{ marginBottom: PARAGRAPH }}>
            Get a glimpse of their adventures and think about all the cool
            things your kid can do when they start their own Rolling Robots
            adventure.
          </Typography>
          <PhotoStepper photos={STUDENTS} yellow={true} />
        </Box>
      </Section> */}
    </>
  )
}

export default FBlandingPage
