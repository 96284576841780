import axios from 'axios'

const getStudents = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/my-students',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getParentStudents = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/get-students',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getStudent = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/get-student',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getActivities = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/get-activities',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const requestInvite = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/request-invite',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const requestSubInvite = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/request-sub-invite',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getActivitiesByStudent = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/get-activities-by-student',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getSubByID = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/get-sub-by-ID',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const checkInvite = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/check-invite',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const deleteInvite = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/delete-invite',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getSubDates = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/get-subscription-dates',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

export const deleteChild = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/delete-child',
      data
    )
    if (resp.status === 200) {
      return { success: true }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const getAccountData = async (data) => {
  try {
    let resp = await axios.get(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/account',
      data
    )
    if (resp.status === 200 && resp.data) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

// export const saveDefaultLocation = async (data) => {
//   try {
//     let resp = await axios.post(
//       process.env.REACT_APP_LOCAL_URL + '/api/parent/save-default-location',
//       data
//     )
//     if (resp.status === 200 && resp.data._id) {
//       return { success: true, data: resp.data }
//     } else {
//       return resp
//     }
//   } catch (err) {
//     return err
//   }
// }

export const saveDefaultRegion = async (data) => {
  try {
    let resp = await axios.post(
      process.env.REACT_APP_LOCAL_URL + '/api/parent/save-default-region',
      data
    )
    if (resp.status === 200 && resp.data._id) {
      return { success: true, data: resp.data }
    } else {
      return resp
    }
  } catch (err) {
    return err
  }
}

const ParentAPI = {
  getStudents,
  getParentStudents,
  requestInvite,
  getActivitiesByStudent,
  getSubByID,
  checkInvite,
  requestSubInvite,
  deleteInvite,
  getStudent,
  getActivities,
  getSubDates,
  deleteChild,
  getAccountData,
  saveDefaultRegion
}

export default ParentAPI
