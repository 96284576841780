import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingComp from '../../util/loading'
import { Grid } from '@mui/material'
import { setEdit } from '../../../store/subscriptionSlice'
import SubscriptionCard from './subscriptionCard'
import LocationSelector from './locationSelector'

const SubscriptionTable = ({ setCurrTab, location }) => {
  const dispatch = useDispatch()
  const subscriptions = useSelector(
    (state) => state.subscription.filteredSubscriptions
  )
  const [sortedSubs, setSortedSubs] = useState(subscriptions)
  /**Automatically set edit value to false when viewing subscription table
  to toggle add/edit button in navbar*/
  useEffect(() => {
    dispatch(setEdit(false))
  }, [])

  useEffect(() => {
    //Sort subscriptions based on whether or not they are active
    let tmpSubs = [...subscriptions]

    tmpSubs = tmpSubs.sort((a, b) => {
      return Number(b.active) - Number(a.active)
    })

    setSortedSubs(tmpSubs)
  }, [subscriptions])

  return (
    <>
      <LocationSelector expanded={true} />
      {sortedSubs.length > 0 ? (
        <Grid
          container
          justifyContent='center'
          spacing={2}
          sx={{ margin: '0px auto 100px auto', width: '90%' }}
        >
          {sortedSubs.map((subscription) => {
            return (
              <Grid item xs={12} md={6} key={subscription._id}>
                <SubscriptionCard
                  subscription={subscription}
                  setCurrTab={setCurrTab}
                />
              </Grid>
            )
          })}
        </Grid>
      ) : (
        <>
          {location == 'all' ? (
            <LoadingComp />
          ) : (
            <h1>There are no memberships at this location</h1>
          )}
        </>
      )}
    </>
  )
}

export default SubscriptionTable
