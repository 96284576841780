import React from 'react'
import { Box } from '@mui/material'

const AboutUsContainer = () => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '50% auto', width: '80%', margin: '20px auto' }}>
      <Box
        alt='founders'
        sx={{
          backgroundImage: `url(${require('../../images/founders.jpg')})`,
          backgroundSize: 'cover',
          gridRowStart: { xs: 1, lg: null },
          gridColumn: { xs: '1/3', lg: '1/2' },
          margin: '50px 50px',
          minHeight: '400px',
          backgroundPosition: 'center top',
          maxWidth: '800px',
        }}
      />
      <Box sx={{gridColumn: {xs: '1/3', lg: '2/3'}, textAlign: 'left'}}>
        <h1>Carpe Futurum!</h1>
        <p>
          We love robots. We dig kids. And we're inspired by the magic that
          happens when the two combine. Sparks fly… literally! Robots ignite the
          imagination of kids everywhere. They open the door to the world of
          technology through the universal language of FUN.
        </p>
        <p>
          When we began Rolling Robots, it wasn't because we're nerdy aerospace
          engineers (ok, maybe it was a little bit) but because we're parents
          that love watching our kids discover their inner-scientist and get
          super excited about creating something of their own. There's no
          mistaking the pride in a kid's face when they hold up a robot they
          created and exclaim, "Look what I made!"
        </p>
        <p>
          We believe that today's greatest minds were once the kids that always
          asked, "How does that work?" We want to be the ones to reply, "Here's
          how!" and "Let's find out!" Our mission is to tap into the spirit of
          wonder in kids, unlocking their future potential with the fun of the
          robot world.
        </p>
        <p>
          Simply put, we want to inspire generations of dreamers and doers - the
          next Edison, the next Jobs, or even the next Franklin. Our dream is
          that one day these kids will look back and think, "My dream was
          sparked by a robot."
        </p>
        <p>End transmission…. Beep. Bop. Boop.</p>
      </Box>
    </Box>
  )
}

export default AboutUsContainer
