import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, TextField, Button } from '@mui/material'
import cards from '../util/cards'
import { setStartDate, setDateConfirmed } from '../../store/cartSlice'
import dateHandler from '../../utils/dateHandler'

const DateSelect = () => {
  const dispatch = useDispatch()

  let isSignedIn = useSelector((state) => state.login.isLoggedIn)
  const startDate = useSelector((state) => state.cart.startDate)
  const confirmed = useSelector((state) => state.cart.dateConfirmed)
  let today = new Date().toISOString().split('T')[0]

  const [error, setError] = useState(false)
  const [date, setDate] = useState(null)

  const BlankCard = cards.blankCard

  const handleChange = (e) => {
    setDate(e.target.value)
  }

  const handleConfirm = () => {
    if (date === null) {
      dispatch(setStartDate(today))
      dispatch(setDateConfirmed(true))
      setError(false)
    } else {
      if (
        dateHandler.isOverFiveDaysFromNow(date) ||
        dateHandler.isToday(date)
      ) {
        dispatch(setStartDate(date))
        dispatch(setDateConfirmed(true))
        setError(false)
      } else {
        setError(true)
      }
    }
  }

  const handleUndo = () => {
    setDate(null)
    dispatch(setStartDate(null))
    dispatch(setDateConfirmed(false))
  }

  return (
    <>
      {isSignedIn ? (
        <>
          <div style={{ display: 'flex' }}>
            <Typography
              variant={'h4'}
              sx={{ paddingTop: '10px', marginRight: '10px' }}
            >
              3. Start Date
            </Typography>
            <Typography
              variant='caption'
              align='center'
              style={{ marginTop: '25px' }}
            >
              (leave unchanged if starting within five days)
            </Typography>
          </div>

          <BlankCard>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {confirmed ? (
                <Typography variant='h6'>{startDate}</Typography>
              ) : (
                <TextField
                  fullWidth
                  type={'date'}
                  onChange={(e) => handleChange(e)}
                  error={error}
                  helperText={
                    error
                      ? `If not today's date, your selection must be at least five days in the future`
                      : ''
                  }
                  value={date}
                  defaultValue={today}
                />
              )}

              {confirmed ? (
                <Button onClick={handleUndo}>Undo</Button>
              ) : (
                <Button onClick={handleConfirm}>Confirm</Button>
              )}
            </div>
          </BlankCard>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default DateSelect
