import React, { useState, useEffect } from 'react'
import timeHandler from '../../utils/timeHandler'
import dateHandler from '../../utils/dateHandler'
import ageHandler from '../../utils/ageFormatting'
import { useSelector } from 'react-redux'
import { Box, Typography, Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const WorkshopInfo = ({
  workshop,
  showAdminDetails,
  expandedAdminDetails,
  showParentDetails,
  showCourseLesson,
  location
}) => {
  const navigate = useNavigate()
  const courses = useSelector((state) => state.parent.courses)
  const [courseInfo, setCourseInfo] = useState(null)
  let siteURL =
    process.env.REACT_APP_MODE === 'DEVELOP'
      ? 'http://localhost:3000/'
      : 'https://www.rollingrobots.net/'

  let count = workshop.info?.students?.length || workshop.students?.length || 0
  let stripeID = workshop.info?.stripeID || workshop.stripeID
  let ageMin = workshop.info?.ageMin || workshop.ageMin
  let ageMax = workshop.info?.ageMax || workshop.ageMax
  let instructorName = 'None'
  if (workshop.instructor) {
    instructorName = `${workshop.instructor.info.name.fName} ${workshop.instructor.info.name.lName}`
  }

  useEffect(() => {
    let mounted = true
    async function handleAsync() {
      let tmp = courses.find(
        (course) => course._id === workshop.metadata.course
      )
      if (mounted) {
        setCourseInfo(tmp)
      }
    }
    if (courses) {
      handleAsync()
    }
    return () => (mounted = false)
  }, [])

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto auto'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gridColumn: '1/3'
          }}
        >
          <Typography component='div' variant='h3' align='left'>
            {workshop.metadata.courseName}{' '}
            {location ? ` - ${workshop.metadata.locationName}` : ''}
          </Typography>
          {workshop.info.sale ? <Chip label='SALE' color='primary' sx={{'&.MuiChip-label': {fontWeight: 'bold'}}} /> : <></>}
        </Box>
        <div style={{ display: 'grid', justifyItems: 'center' }}>
          <div
            style={{
              zIndex: 10,
              gridArea: '1 / 1 / 2 / 2',
              height: '100px',
              width: '100px',
              borderRadius: '50%',
              WebkitBoxShadow: `inset 0 0 0 10px ${ageHandler.ageGroupColor(
                ageMin,
                ageMax
              )}`,
              boxShadow: `inset 0 0 0 10px ${ageHandler.ageGroupColor(
                ageMin,
                ageMax
              )}`
            }}
          ></div>
          <img
            style={{
              gridArea: '1 / 1 / 2 / 2',
              borderRadius: '50%'
            }}
            height='100'
            width='100'
            alt='Icon of course'
            src={require(`../../images/courseIcons/${
              workshop.info?.icon || workshop.icon || 'robie.png'
            }`)}
          />
        </div>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ textAlign: 'left', flexGrow: 1 }}>
            {workshop.priceInfo ? (
              <Typography variant='h6'>{`$${
                workshop.priceInfo?.unit_amount / 100
              }`}</Typography>
            ) : (
              <></>
            )}
            <Typography color='text.secondary' component='div'>
              {dateHandler.isConsecutiveDates(workshop.info.selectDates) ? (
                <>
                  {dateHandler.formatWithWeekday(workshop.metadata.startDate)} -{' '}
                  {dateHandler.formatWithWeekday(workshop.metadata.endDate)}
                  {dateHandler.isFourDays(
                    workshop.metadata.startDate,
                    workshop.metadata.endDate
                  )
                    ? '*'
                    : null}
                </>
              ) : (
                <>{dateHandler.formatDateList(workshop.info.selectDates)}</>
              )}
            </Typography>
            <Typography color='text.secondary' component='div'>
              {timeHandler.getTimeString(workshop.metadata)}
            </Typography>
            {location ? (
              <>
                <Typography variant='body2' color='text.secondary'>
                  Address: {location.address.lineOne} {location.address.lineTwo}{' '}
                  {location.address.city}
                </Typography>
              </>
            ) : (
              <></>
            )}
            <span style={{ display: 'flex' }}>
              <Typography
                color='text.secondary'
                component='div'
                style={{ width: '50%' }}
              >
                <Chip
                  sx={{
                    color: '#fff',
                    backgroundColor: ageHandler.ageGroupColor(ageMin, ageMax)
                  }}
                  label={ageHandler.formatRange(ageMin, ageMax)}
                />
              </Typography>
            </span>
          </Box>
        </Box>
      </Box>
      {showAdminDetails ? (
        <Box
          sx={{
            borderTop: '1px solid grey',
            marginTop: '10px',
            textAlign: 'left'
          }}
        >
          <Typography variant='body2' color='text.secondary' component='div'>
            {count} Enrolled
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <Typography variant='body2' color='text.secondary' component='div'>
              Instructors:
            </Typography>
            {workshop.instructors.length == 0 ? (
              <Typography>None assigned</Typography>
            ) : (
              workshop.instructors.map((instructor) => (
                <Chip
                  key={instructor._id}
                  label={`${instructor.info.name.fName} ${instructor.info.name.lName} `}
                  size='small'
                  onClick={() => navigate(`/staff-view/${instructor._id}`)}
                />
              ))
            )}
          </div>
          {expandedAdminDetails && instructorName !== 'None' ? (
            <>
              <Typography
                variant='body2'
                color='text.secondary'
                component='div'
              >
                Instructor Email: {workshop.instructor.userName}
              </Typography>
              <Typography
                variant='body2'
                color='text.secondary'
                component='div'
              >
                Instructor Phone: {workshop.instructor.info.contact.phone}
              </Typography>
            </>
          ) : null}
          <Typography variant='body2' color='text.secondary' component='div'>
            Stripe ID: {stripeID}
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center'
            }}
          >
            <Typography style={{ marginRight: '5px' }}>Link: </Typography>
            <Typography variant='caption'>{`${siteURL}direct/?type=workshop&id=${workshop.id}`}</Typography>
          </div>
        </Box>
      ) : null}
      {showParentDetails ? (
        <>
          {courseInfo ? (
            <>
              <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
                Description:
              </Typography>
              <Typography>{courseInfo.description}</Typography>
            </>
          ) : (
            <>loading...</>
          )}
        </>
      ) : (
        <></>
      )}
      {showCourseLesson && courseInfo?.lesson ? (
        <>
          <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
            Course Info:
          </Typography>
          <a target='_blank' rel='noreferrer' href={courseInfo.lesson.url}>
            Click Here for details
          </a>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default WorkshopInfo
