import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getOrders } from '../../api/admin'
import LoadingComp from '../../component/util/loading'
import AdminOrderTable from '../../component/adminViews/ordersTable'
import OrderFilter from '../../component/adminViews/orderFilter'
import SecondaryNav from '../../component/util/secondaryNav'
import Report from '../../component/adminViews/financialReport/report'
import MembershipReport from '../../component/adminViews/membershipReport/membershipReport'
import AlternatePaymentsTable from '../../component/adminViews/alternatePaymentsTable'

const AdminOrdersContainer = () => {
  let user = useSelector((state) => state.login.user)
  const [isLoading, setIsLoading] = useState(true)
  const [orders, setOrders] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [filteredOrders, setFilteredOrders] = useState(null)
  const [currTab, setCurrTab] = useState(0)
  const [count, setCount] = useState(0)

  useEffect(() => {
    async function handleAsync() {
      setIsLoading(true)
      let resp = await getOrders({
        params: { uID: user._id, count: count, search: searchValue }
      })
      if (resp.success) {
        setOrders(resp.data)
      } else {
        alert('There was a problem retrieving orders')
      }

      setIsLoading(false)
    }
    handleAsync()
  }, [count])

  const handleSearch = async () => {
    setIsLoading(true)
    let resp = await getOrders({
      params: { uID: user._id, count: count, search: searchValue }
    })
    if (resp.success) {
      setOrders(resp.data)
    } else {
      alert('There was a problem retrieving orders')
    }

    setIsLoading(false)
  }
  const handleChange = (value) => {
    setSearchValue(value)
  }
  const handleReset = () => {
    setFilteredOrders(null)
    setSearchValue('')
  }

  const handlePageChange = (direction) => {
    if (direction === 'back') {
      setCount(count - 20)
    } else {
      setCount(count + 20)
    }
  }

  const TABS = [
    {
      label: 'Overview',
      content: (
        <>
          <OrderFilter
            changeSearchValue={handleChange}
            handleSearch={handleSearch}
            searchValue={searchValue}
            handleReset={handleReset}
            filteredItems={filteredOrders}
          />
          {isLoading ? (
            <LoadingComp />
          ) : (
            <AdminOrderTable
              data={filteredOrders ? filteredOrders : orders}
              handlePageChange={handlePageChange}
              count={count}
            />
          )}
        </>
      )
    },
    { label: 'Stripe Reports', content: <Report /> },
    { label: 'Membership Reports', content: <MembershipReport /> },
    { label: 'Alternate Payments', content: <AlternatePaymentsTable /> }
  ]
  return (
    <React.Fragment>
      <SecondaryNav
        title='Orders'
        currTab={currTab}
        setCurrTab={setCurrTab}
        tabs={TABS}
      />
      {TABS[currTab].content}
    </React.Fragment>
  )
}

export default AdminOrdersContainer
