const STYLES = {
  box: {
    width: { xs: '90%', md: '70%' },
    maxWidth: '1200px',
    margin: 'auto auto'
  },
  yellow: '#F3C34B',
  lightGrey: '#F2F4F7',
  red: '#EE4239',
  green: '#6EA44F',
  lightBlue: '#ADC5E7',
  blue: '#56A1D6',
  purple: '#8F7EB5',
  darkGrey: '#3e3e3e'
}

export default STYLES
