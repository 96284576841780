import React from 'react'
import IconButton from '@mui/material/IconButton'
import { TextField, Box } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

const OrderFilter = ({
  searchValue,
  handleSearch,
  changeSearchValue,
  handleReset,
  filteredItems
}) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <TextField
          autoFocus
          id='search-user'
          label='Search'
          value={searchValue}
          onChange={(e) => {
            changeSearchValue(e.target.value)
          }}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
          InputProps={
            !filteredItems
              ? {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          handleSearch()
                        }}
                        edge='end'
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              : {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          handleReset()
                        }}
                        edge='end'
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
          }
        />
      </Box>
    </React.Fragment>
  )
}

export default OrderFilter
